<mat-toolbar color="primary">
  <img routerLink="." src="/assets/favicon-32x32.png" />
  <span routerLink=".">In:Range:Admin</span>

  <!-- This fills the remaining space of the current row -->
  <span class="example-fill-remaining-space"></span>
  <mat-icon matListIcon>account_circle</mat-icon><span>{{ loggedinUser }}</span>
  <mat-menu #appMenu="matMenu">
    <a mat-menu-item routerLink="/customer"><mat-icon>view_comfy</mat-icon><span>Customers</span></a>
    <a mat-menu-item routerLink="/sites"><mat-icon>directions_boat</mat-icon><span>Sites</span></a>
    <a mat-menu-item routerLink="/hw"><mat-icon>developer_mode</mat-icon><span>Hardware</span></a>
    <a mat-menu-item routerLink="/sensor-type-definitions"><mat-icon>note_outline</mat-icon><span>SensorTypes</span></a>
    <a mat-menu-item *ngIf="loggedin" (click)="signout()"><mat-icon>do_not_disturb</mat-icon><span>Signout</span></a>
    <a mat-menu-item *ngIf="!loggedin" (click)="signing()"><mat-icon>touch_app</mat-icon><span>Signout</span></a>
  </mat-menu>

  <button *ngIf="loggedin" mat-icon-button [matMenuTriggerFor]="appMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
</mat-toolbar>
<div class="basic-container">
  <router-outlet></router-outlet>
</div>
