import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Guid } from './guid';
@Injectable()
export class SpatialDataService {
  constructor(private httpClient: HttpClient) {}

  private readonly apiAddress = environment.apiAddress + '/SpatialData';

  // ====================== SpatialData ============================
  getSpatialData() {
    return this.httpClient.get<SpatialDataObjectGroupReadModel[]>(this.apiAddress);
  }

  putSpatialData(spatialDataToAddOrUpdate: (SpatialDataObjectGroupWriteModel | SpatialDataObjectGroupReadModel)[]) {
    return this.httpClient.post(this.apiAddress, spatialDataToAddOrUpdate);
  }

  deleteSpatialDataGroup(spatialDataGroupId: string): Observable<string> {
    return this.httpClient.delete(this.apiAddress + '/group/' + spatialDataGroupId, { responseType: 'text' });
  }
  deleteSpatialDataObject(spatialDataObjectId: string): Observable<string> {
    return this.httpClient.delete(this.apiAddress + '/objects/' + spatialDataObjectId, {
      responseType: 'text',
    });
  }
}

export interface SpatialDataObjectGroupReadModel extends SpatialDataObjectGroupWriteModel {
  id: string;
}

export interface SpatialDataObjectGroupWriteModel {
  label: string;
  /**
   * GeoJson string
   */
  polygon: string | null;
  spatialDataObjects: SpatialDataObjectReadModel[];
  sites: Guid[];
}

export interface SpatialDataObjectReadModel {
  id: string;
  label: string;
  /**
   * Ex: "Windmill", "Port", "Platform"
   */
  type: string | null;
  /**
   * GeoJson string
   */
  location: string;
}
