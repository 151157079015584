import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.css'],
    standalone: false
})
export class StatusComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
