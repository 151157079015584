import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SensorTypeDefinition, SensorTypeService } from '../services/sensorType.service';

@Component({
  selector: 'app-sensor-type-definitions',
  templateUrl: './sensorTypes.component.html',
  styleUrls: ['./sensorTypes.component.css'],
})
export class SensorTypesComponent implements OnInit {
  sensorTypes: MatTableDataSource<SensorTypeDefinition> = new MatTableDataSource();
  constructor(private sensorTypeService: SensorTypeService, public dialog: MatDialog, public snackBar: MatSnackBar) {}

  displayedColumns = [
    'id',
    'name',
    'sensorTypeId',
    'revision',
    'description',
    'measurementDefinitions',
    'modbusDeviceType',
    'sourceJson',
    'clone',
  ];

  ngOnInit() {
    this.fetchSensorTypes();
  }

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.sensorTypes.sort = this.sort;
  }

  fetchSensorTypes(): void {
    this.sensorTypeService.getSensorTypes().subscribe(
      (s) => {
        this.sensorTypes.data = s;
      },
      (err: HttpErrorResponse) => {
        this.snackBar.open(`There was an error fetching sensorTypes ${JSON.stringify(err.error)}`, null, {
          duration: 10000,
        });
        console.error(err);
      }
    );
  }

  openDialog(prefilledJson?: string): void {
    console.debug('OpenDialog triggered');
    const dialogRef = this.dialog.open(NewModbusConfigDialogComponent, {
      width: '800px',
      data: { prefilledJson },
    });
    dialogRef.afterClosed().subscribe((success = false) => {
      if (success) {
        this.fetchSensorTypes();
      }
    });
  }
}

@Component({
  selector: 'azure-ad-new-modbus-configuration-dialog',
  template: `<mat-card appearance="outlined">
    <h3>New Modbus Configuration</h3>
    <mat-form-field appearance="fill" style="width: 99%;">
      <mat-label>ModbusConfiguration</mat-label>
      <textarea matInput rows="20" cols="30" [(ngModel)]="modbusConfig"></textarea>
    </mat-form-field>
    <mat-card-actions>
      <button mat-raised-button color="primary" [disabled]="!modbusConfig" (click)="onSaveClick()">Create</button>
      <button mat-raised-button (click)="onCancelClick()">Cancel</button>
    </mat-card-actions>
  </mat-card> `,
})
export class NewModbusConfigDialogComponent {
  modbusConfig: string;
  constructor(
    public dialogRef: MatDialogRef<NewModbusConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { prefilledJson?: string },
    private sensorTypeService: SensorTypeService,
    public snackBar: MatSnackBar
  ) {
    this.modbusConfig = data.prefilledJson ? JSON.stringify(JSON.parse(data.prefilledJson), null, '  ') : '';
  }

  onSaveClick(): void {
    try {
      try {
        JSON.parse(this.modbusConfig);
      } catch (error) {
        this.snackBar.open(
          `ERROR: There seems to be an error with the json formatting. Unable to parse JSON. ${error}`,
          null,
          {
            duration: 10000,
          }
        );
      }
      if (this.modbusConfig) {
        setTimeout(() => {
          this.sensorTypeService.addSensorTypes(this.modbusConfig).subscribe(
            (s) => {
              this.snackBar.open(`Successfully added sensorType`, null, { duration: 10000 });
              console.log(s);
              this.dialogRef.close(true);
            },
            (err: Error) => {
              this.snackBar.open(
                `There was an error adding config ${JSON.stringify(
                  err instanceof HttpErrorResponse ? err.error : err
                )}`,
                null,
                {
                  duration: 10000,
                }
              );
              console.error(err);
            }
          );
        }, 1000);
      }
    } catch (err) {
      this.snackBar.open(`There seems to be an error with the json format: ${err?.message ?? err}`, null, {
        duration: 10000,
      });
    }
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
}
