<mat-paginator
  #paginator
  [(pageSize)]="limit"
  [pageIndex]="page"
  [pageSizeOptions]="[30, 50, 100, 300]"
  [length]="1000"
  (page)="onPageChange($event)"
>
</mat-paginator>
<mat-table
  #table
  matSort
  (matSortChange)="onSortChange($event)"
  [dataSource]="hardwareDevicesDataSource"
  style="max-height: 60vh; overflow-y: scroll"
>
  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef class="w-64">
      <mat-checkbox
        (change)="$event ? masterToggle() : null"
        [checked]="selected.hasValue() && isAllSelected()"
        [indeterminate]="selected.hasValue() && !isAllSelected()"
        class="w-64"
      >
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" class="w-64">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? selected.toggle(element) : null"
        [checked]="selected.isSelected(element)"
      >
      </mat-checkbox>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Id </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="serial">
    <mat-header-cell *matHeaderCellDef mat-sort-header> SerialNo </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.serial }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="macAddress">
    <mat-header-cell *matHeaderCellDef mat-sort-header> MAC address </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.macAddress }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="bleMacAddress">
    <mat-header-cell *matHeaderCellDef mat-sort-header> BLE-MAC address </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.bleMacAddress }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="deviceType">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Devicetype </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.deviceType }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="siteName">
    <mat-header-cell *matHeaderCellDef mat-sort-header> SiteName </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.site?.name }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="lastObserved">
    <mat-header-cell *matHeaderCellDef mat-sort-header> LastObserved </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.lastObserved | date: 'yyyy-MM-dd - HH:mm:ss' }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="options">
    <mat-header-cell *matHeaderCellDef class="w-100"> Options </mat-header-cell>
    <mat-cell *matCellDef="let element" class="w-100">
      <button mat-button [routerLink]="[element.id]">Details</button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
