import { Component, OnInit } from '@angular/core';
import { Broadcaster } from '../services/broadcast.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-auth-callback',
    templateUrl: './auth-callback.component.html',
    styleUrls: ['./auth-callback.component.css'],
    standalone: false
})
export class AuthCallbackComponent implements OnInit {
  constructor(private broadcaster: Broadcaster, private router: Router) {}
  ngOnInit() {
    this.router.navigateByUrl('customer');
  }
}
