<div *ngIf="site; let site; else: loading">
  <h1>Site: {{ site.name }}</h1>
  <a [href]="siteRemoteUrl" target="_blank">{{ siteRemoteUrl }}</a>

  <!-- <button mat-raised-button color="primary" (click)="saveConfig()">Save</button>
  <button mat-raised-button (click)="cancelConfig()">Cancel</button> -->

  <mat-tab-group [selectedIndex]="selectedTabGroupIndex" (selectedIndexChange)="onTabGroupChange($event)">
    <mat-tab label="Details">
      <div class="inputcontainer">
        <mat-form-field>
          <input matInput type="string" disabled placeholder="Id" [(ngModel)]="site.id" />
        </mat-form-field>
        <mat-form-field>
          <input matInput type="string" placeholder="Name" [(ngModel)]="site.name" />
        </mat-form-field>
        <mat-form-field>
          <input matInput type="string" placeholder="IMO Number" [(ngModel)]="site.imoNumber" />
        </mat-form-field>
        <mat-form-field>
          <input matInput type="string" placeholder="MMSI Number" [(ngModel)]="site.mmsi" />
        </mat-form-field>
        <mat-form-field>
          <input matInput type="string" placeholder="ImageUrl" [(ngModel)]="site.imageUrl" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>State</mat-label>
          <select matNativeControl required [(ngModel)]="site.state">
            <option *ngFor="let state of siteStates" [value]="state">{{ siteStatesEnum[state] }}</option>
          </select>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="string" placeholder="ReleaseTag" [(ngModel)]="site.releaseTag" />
        </mat-form-field>
        <div style="display: flex; flex-direction: row; gap: 24px">
          <mat-checkbox [(ngModel)]="featureFlags.connectPOB">ConnectPOB</mat-checkbox>
          <mat-checkbox [(ngModel)]="featureFlags.gangway">Gangway</mat-checkbox>
          <mat-checkbox [(ngModel)]="featureFlags.connectFuel">ConnectFuel</mat-checkbox>
          <mat-checkbox [(ngModel)]="featureFlags.cargoConditionMonitoring">CargoConditionMonitoring</mat-checkbox>
          <mat-checkbox [(ngModel)]="featureFlags.cabinBunkManagement">CabinBunkManagement</mat-checkbox>
        </div>
        <mat-form-field>
          <mat-label>Comment</mat-label>
          <textarea matInput rows="7" cols="20" [(ngModel)]="site.comment"></textarea>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="saveSite()">Save site details</button>
        <div style="display: flex; flex-direction: row">
          <div id="mapContainer" *ngIf="site && site.imoNumber && site.imoNumber.length == 7">
            <!-- https://www.vesselfinder.com/embed -->
            <iframe
              name="vesselfinder"
              id="vesselfinder"
              width="800px"
              height="600px"
              frameborder="0"
              [src]="vesselMapSrcUrl"
            >
              Browser does not support embedded objects.<br />Visit directly
              <a href="https://www.vesselfinder.com" target="_blank">www.vesselfinder.com</a>'
            </iframe>
          </div>
          <div *ngIf="!site || !site.imoNumber || site.imoNumber == '' || site.imoNumber.length != 7">
            Vessel map will appear when imoNumber is saved.
          </div>
          <img *ngIf="!!site.imageUrl" [src]="site.imageUrl" [alt]="'Image for site' + site.name" height="400" />
        </div>
      </div>
    </mat-tab>

    <!-- ============ Layers ============ -->
    <mat-tab label="Layers">
      <!-- ============ Map ============ -->
      <mat-card appearance="outlined">
        <mat-card-title>Map size</mat-card-title>
        <div class="inputcontainer">
          <mat-form-field>
            <input matInput type="number" placeholder="Width" [(ngModel)]="view.width" />
          </mat-form-field>
          <mat-form-field>
            <input matInput type="number" placeholder="Height" [(ngModel)]="view.height" />
          </mat-form-field>
          <button mat-raised-button color="primary" (click)="saveView()">Save dimensions</button>
        </div>
      </mat-card>

      <!-- ============ Baselayers ============ -->
      <mat-card appearance="outlined">
        <mat-card-title>Baselayers (only PNG files)</mat-card-title>
        <button mat-button color="primary" (click)="newBaseLayer()">Create baselayer</button>
        <mat-list>
          <mat-list-item *ngFor="let layer of baseLayers">
            <form (ngSubmit)="uploadImage($event, layer)">
              <mat-form-field>
                <mat-select name="name" placeholder="Name" [(ngModel)]="layer.name">
                  <mat-option value="Dayview">Davyiew</mat-option>
                  <mat-option value="Nightview">Nightview</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field style="width: 400px">
                <input
                  matInput
                  name="imageReference"
                  disabled
                  type="string"
                  placeholder="imageReference"
                  [ngModel]="layer.imageReference"
                />
              </mat-form-field>

              <button mat-icon-button (click)="viewImage(layer)">
                <mat-icon matTooltip="Preview image" class="mat-24">photo</mat-icon>
              </button>
              <span *ngIf="!layer.id">
                <input type="file" name="file" (change)="baseLayerFormChange($event, layer)" accept=".png" />

                <button type="submit" mat-icon-button>
                  <mat-icon class="material_icons" matTooltip="Upload selected image">file_upload</mat-icon>
                </button>
              </span>
              <button mat-icon-button (click)="deleteBaseLayer(layer)">
                <mat-icon class="mat-24" matTooltip="Delete baselayer">delete</mat-icon>
              </button>
            </form>
          </mat-list-item>
        </mat-list>
        <img *ngIf="currentImagePath" [src]="currentImagePath" class="previewLayerImage" alt="GA of site" />
      </mat-card>
    </mat-tab>

    <!-- ============ Roles ============ -->
    <mat-tab label="PersonRoles">
      <mat-card appearance="outlined">
        <mat-card-title>Roles</mat-card-title>
        <div>
          <button mat-button color="primary" (click)="newRole()">Add role</button>
          <button mat-raised-button color="primary" (click)="saveRoles()">Save roles</button>
        </div>
        <mat-list>
          <mat-list-item *ngFor="let role of roles">
            <mat-form-field>
              <input matInput name="name" type="string" placeholder="Name" [(ngModel)]="role.name" />
            </mat-form-field>
            <button mat-raised-button color="danger" (click)="deleteRole(role)">Delete</button>
          </mat-list-item>
        </mat-list>
        <img *ngIf="currentImagePath" [src]="currentImagePath" class="previewLayerImage" />
      </mat-card>
    </mat-tab>

    <mat-tab label="MusterTypes">
      <mat-card appearance="outlined">
        <mat-card-title>MusterTypes</mat-card-title>
        <div>
          <button mat-button color="primary" (click)="newMusterType()">Add musterType</button>
          <button mat-raised-button color="primary" (click)="saveMusterTypes()">Save musterTypes</button>
        </div>
        <mat-list>
          <mat-list-item *ngFor="let musterType of musterTypes">
            <mat-form-field>
              <input matInput name="name" type="string" placeholder="Name" [(ngModel)]="musterType.label" />
            </mat-form-field>
            <button mat-raised-button color="danger" (click)="deleteMusterType(musterType)">Delete</button>
          </mat-list-item>
        </mat-list>
        <img *ngIf="currentImagePath" [src]="currentImagePath" class="previewLayerImage" />
      </mat-card>
    </mat-tab>

    <!-- ============ Nodes ============ -->
    <mat-tab label="Nodes">
      <app-sitedetails-sensors></app-sitedetails-sensors>
    </mat-tab>

    <!-- ============ Tags ============ -->
    <mat-tab label="Tags">
      <app-sitedetails-tags></app-sitedetails-tags>
    </mat-tab>

    <!-- ============ Edgedevices ============ -->
    <mat-tab label="Edgedevices">
      <app-sitedetails-edgedevices></app-sitedetails-edgedevices>
    </mat-tab>

    <!-- ============ Settings ============ -->
    <mat-tab label="Settings">
      <mat-card appearance="outlined">
        <mat-card-title>Settings</mat-card-title>
        <div>
          <button mat-button color="primary" (click)="newSetting()">Add setting</button>
          <button mat-raised-button color="primary" (click)="saveSettings()">Save settings</button>
        </div>
        <mat-list>
          <mat-list-item *ngFor="let setting of settings">
            <mat-form-field style="width: 300px">
              <input matInput name="key" type="string" placeholder="Key" [(ngModel)]="setting.key" />
            </mat-form-field>
            <mat-form-field style="margin-left: 20px; width: 600px">
              <input matInput name="value" type="string" placeholder="Value" [(ngModel)]="setting.value" />
            </mat-form-field>
            <mat-form-field style="margin-left: 20px; width: 300px">
              <input
                matInput
                name="description"
                type="string"
                placeholder="Description"
                [(ngModel)]="setting.description"
              />
            </mat-form-field>
            <button mat-raised-button color="danger" (click)="deleteSetting(setting)">Delete</button>
          </mat-list-item>
        </mat-list>
        <img *ngIf="currentImagePath" [src]="currentImagePath" class="previewLayerImage" />
      </mat-card>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #loading>
  <div class="loading-indicator">
    <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
  </div>
</ng-template>

<router-outlet></router-outlet>
