import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmDialogComponent } from './../confirm-dialog/confirm-dialog.component';
import { EdgeDevice, Site, SiteService, BulkAddHardwareToSite } from './../services/site.service';
import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { HardwareService, HardwareDevice, HardwareDeviceSearch } from '../services/hw.service';
import { EdgeDeviceService } from '../services/edgeDevice.service';
import { AngularWaitBarrier } from 'blocking-proxy/built/lib/angular_wait_barrier';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-sitedetails-edgedevices',
  templateUrl: './sitedetails-edgedevices.component.html',
  styleUrls: ['./sitedetails-edgedevices.component.css'],
})
export class SitedetailsEdgedevicesComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private siteService: SiteService,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar
  ) {}

  @ViewChild('edgeDeviceTable', { static: true }) edgeDeviceTable: MatTable<EdgeDevice>;

  public displayedColumns = [
    'select',
    'id',
    'azureIotEdgeDeviceId',
    'azureIotHubHostName',
    'authTenantId',
    'authApplicationId',
    'options',
  ];

  public selected = new SelectionModel<EdgeDevice>(true, []);
  private siteId: string;
  public edgeDevices: EdgeDevice[];

  ngOnInit(): void {
    this.siteId = this.route.snapshot.paramMap.get('siteId');
    this.getEdgeDevices();
  }
  onDisconnectSelectedClick() {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
    this.disconnectEdgeDevices(this.selected.selected.map((hd) => hd.id));
  }

  getEdgeDevices() {
    this.siteService.getEdgeDevices(this.siteId).subscribe((s) => {
      this.edgeDevices = s;
    });
  }
  isAllSelected() {
    const numSelected = this.selected.selected.length;
    const numRows = this.edgeDevices.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.edgeDeviceTable.renderRows();
    if (this.isAllSelected()) {
      this.selected.clear();
    } else {
      this.edgeDevices.forEach((ed) => this.selected.select(ed));
    }
  }

  connectEdgedevice() {
    const dialogRef = this.dialog.open(ConnectEdgeDeviceComponent, {
      width: '80%',
    });

    dialogRef.afterClosed().subscribe((hardwareIds) => {
      if (hardwareIds && hardwareIds.length > 0) {
        this.siteService
          .connectHardwareDevices(this.siteId, {
            safeMode: false,
            hardwareIds: hardwareIds,
          })
          .subscribe(
            (s) => {
              this.snackBar.open(s, null, { duration: 4000 });
              this.getEdgeDevices();
            },
            (err: HttpErrorResponse) => {
              this.snackBar.open('There was an error connecting edgeDevice ' + err.error, null, { duration: 10000 });
              console.error(err);
            }
          );
      }
    });
  }
  onDisconnectClick(device) {
    this.disconnectEdgeDevices([device.id]);
  }

  disconnectEdgeDevices(hardwareIds) {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
    confirmDialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        const bulkRemoveHardwareToSite: BulkAddHardwareToSite = {
          safeMode: false,
          hardwareIds: hardwareIds,
        };
        this.siteService.disconnectHardwareDevices(this.siteId, bulkRemoveHardwareToSite).subscribe(
          (s) => {
            this.snackBar.open(s, null, { duration: 4000 });
            this.getEdgeDevices();
          },
          (err: HttpErrorResponse) => {
            this.snackBar.open('There was an error disconnecting edgeDevice ' + err.error, null, { duration: 10000 });
            console.error(err);
          }
        );
      }
    });
  }
}

@Component({
  selector: 'app-connect-edgedevice-dialog',
  template: `<mat-card appearance="outlined">
    <h3>Connect edgeDevice</h3>
    <app-hardware-search-field (onSubmit)="queryHardwareFormSubmit($event)"></app-hardware-search-field>
    <app-hardware-table
      [(orderBy)]="query.OrderBy"
      [(orderDirection)]="query.OrderDirection"
      [(limit)]="query.Limit"
      [(page)]="query.Page"
      [(selectedHardware)]="selectedHardware"
      [hardwareDevices]="hardwareDevices"
      [displayedColumns]="displayedColumns"
      (change)="getHardwareDevices()"
    ></app-hardware-table>
    <mat-card-actions>
      <button mat-raised-button (click)="onSaveClick()">
        Connect all {{ selectedHardware.length }} selected EdgeDevices
      </button>
      <button mat-button (click)="onCancelClick()">Cancel</button>
    </mat-card-actions>
  </mat-card> `,
})
export class ConnectEdgeDeviceComponent {
  constructor(
    public dialogRef: MatDialogRef<ConnectEdgeDeviceComponent>,
    private hwService: HardwareService,
    private edgeDeviceService: EdgeDeviceService
  ) {}
  public hardwareDevices: HardwareDevice[] = [];
  public hardwareDeviceSearch: HardwareDeviceSearch;
  public selectedHardware: HardwareDevice[] = [];
  displayedColumns = ['select', 'id', 'serial', 'macAddress', 'bleMacAddress', 'deviceType'];
  standardQuery: HardwareDeviceSearch = {
    Serial: '',
    MacAddress: '',
    DeviceType: 'SRT-ED',
    Limit: 100,
    Page: 0,
    OrderBy: 'Id',
    OrderDirection: 'asc',
  };
  query = Object.assign(this.standardQuery);

  async onSaveClick() {
    const hardwareIds = this.selectedHardware.map((hd) => hd.id);
    this.dialogRef.close(hardwareIds);
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
  queryHardwareFormSubmit(search: HardwareDeviceSearch) {
    this.query = {
      ...this.standardQuery,
      Limit: this.query.Limit,
      OrderBy: this.query.OrderBy,
      OrderDirection: this.query.OrderDirection,
      ...search,
    };
    this.getHardwareDevices();
  }
  getHardwareDevices(): void {
    this.query.DeviceType = 'SRT-ED';
    this.hwService.searchHardware(this.query).subscribe((data) => {
      this.hardwareDevices = data;
    });
  }
}
