import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  SpatialDataObjectGroupReadModel,
  SpatialDataObjectGroupWriteModel,
  SpatialDataService,
} from '../services/spatialData.service';
import { Site, SiteService } from '../services/site.service';
import * as L from 'leaflet';

@Component({
  selector: 'app-SpatialData',
  templateUrl: './spatialData.component.html',
  styleUrls: ['./spatialData.component.css'],
  standalone: false,
})
export class SpatialDataComponent implements OnInit {
  spatialDataTableSource: MatTableDataSource<SpatialDataObjectGroupReadModel> = new MatTableDataSource();
  spatialData: SpatialDataObjectGroupReadModel[];
  sites: Site[];
  targetConditions: string[];
  selectedSpatialDataGroupId: string | null = null;
  isSpatialDataObjectValid: boolean;
  groupIdsToRender: string[] = [];
  mapLayers: { [id: string]: L.Layer } = {};
  newSpatialDataGroup = {
    label: '',
    polygon: null,
    spatialDataObjects: `[
  {
    "id": null,
    "label": "A52",
    "location": "{'type':'Point','coordinates':[6.0789194,54.0636567]}",
    "type": "Windmill",
    "detectionDistance": null
  }
]`,
    sites: '',
  };
  map: any;
  renderPobVesselsInMap: boolean = true;
  constructor(
    private spatialDataService: SpatialDataService,
    private siteService: SiteService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {}

  displayedColumns = ['id', 'label', 'polygon', 'spatialDataObjects', 'sites', 'siteNames', 'actions'];

  ngOnInit() {
    this.fetchSpatialData();
    this.fetchSites();
    this.initMap();
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [54.0636567, 6.0789194], // Swapped coordinates to be [lat, lng]
      zoom: 5,
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    tiles.addTo(this.map);
  }

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.spatialDataTableSource.sort = this.sort;
  }

  fetchSpatialData(): void {
    this.spatialDataService.getSpatialData().subscribe(
      (s) => {
        this.spatialData = s;
        this.spatialDataTableSource.data = s;

        // Add all spatial data group IDs to groupIdsToRender by default
        this.groupIdsToRender = s.map((group) => group.id);

        // Render all spatial data on the map
        this.renderSpatialDataOnMap();
      },
      (err: HttpErrorResponse) => {
        this.snackBar.open(`There was an error fetching spatialData ${err?.message ?? err}`, null, {
          duration: 10000,
        });
        console.error(err);
      }
    );
  }

  fetchSites() {
    this.siteService.getSites().subscribe(
      (s) => {
        this.sites = s;
        this.renderSpatialDataOnMap();
      },
      (err: HttpErrorResponse) => {
        this.snackBar.open(`There was an error fetching sites ${err?.message ?? err}`, null, {
          duration: 10000,
        });
        console.error(err);
      }
    );
  }

  save(spatialDataGroup: SpatialDataObjectGroupReadModel): void {
    this.spatialDataService.putSpatialData([spatialDataGroup]).subscribe(
      () => {
        this.snackBar.open('Saved successfully', null, {
          duration: 2000,
        });
        this.fetchSpatialData();
      },
      (err: HttpErrorResponse) => {
        this.snackBar.open(`There was an error saving ${err?.message ?? err}`, null, {
          duration: 10000,
        });
        console.error(err);
      }
    );
  }

  handleSitesUpdate(spatialDataGroup: SpatialDataObjectGroupReadModel, event: Event) {
    const target = event.target as HTMLTextAreaElement;
    const siteIds = target.value.split('\n').map((siteId) => siteId.trim());
    spatialDataGroup.sites = siteIds;
  }

  getSiteNames(siteIds: string[]): string {
    const out = siteIds.map((siteId) => this.sites?.find((site) => site.id === siteId)?.name ?? 'Not found').join('\n');
    return out ? out : 'All sites have access';
  }

  handleEditChange(spatialDataGroupId: string) {
    if (this.selectedSpatialDataGroupId === spatialDataGroupId) {
      this.selectedSpatialDataGroupId = null;
    } else {
      this.selectedSpatialDataGroupId = spatialDataGroupId;
    }
  }

  handleSpatialDataObjectsInput(spatialDataGroupId: string, event: Event) {
    const target = event.target as HTMLTextAreaElement;

    try {
      const jsonParsed = JSON.parse(target.value);
      const spatialDataGroup = this.spatialData.find((group) => group.id === spatialDataGroupId);
      if (!spatialDataGroup) {
        console.error('Spatial data group not found');
        return;
      }
      spatialDataGroup.spatialDataObjects = jsonParsed;
    } catch (error) {
      // Invalid json
      this.isSpatialDataObjectValid = false;
    }
  }

  addSpatialDataGroup() {
    try {
      const spatialDataGroupToAdd: SpatialDataObjectGroupWriteModel = {
        label: this.newSpatialDataGroup.label,
        polygon: this.newSpatialDataGroup.polygon,
        spatialDataObjects: JSON.parse(this.newSpatialDataGroup.spatialDataObjects),
        sites: this.newSpatialDataGroup.sites
          .split(',')
          .filter((id) => !!id)
          .map((site) => site.trim()),
      };

      this.spatialDataService.putSpatialData([spatialDataGroupToAdd]).subscribe(
        () => {
          this.snackBar.open('Added successfully', null, {
            duration: 2000,
          });
          this.fetchSpatialData();
        },
        (err: HttpErrorResponse) => {
          this.snackBar.open(`There was an error adding ${err?.message ?? err}`, null, {
            duration: 10000,
          });
          console.error(err);
        }
      );
    } catch (error) {
      this.snackBar.open('Invalid JSON format for spatialDataObjects', null, {
        duration: 2000,
      });
      console.error('Invalid JSON format for spatialDataObjects', error);
    }
  }

  toggleRenderInMap(groupId: string): void {
    if (!this.groupIdsToRender.includes(groupId)) {
      this.groupIdsToRender.push(groupId);
    } else {
      this.groupIdsToRender = this.groupIdsToRender.filter((id) => id !== groupId);
    }

    this.renderSpatialDataOnMap();
  }

  isRenderedInMap(groupId: string) {
    return this.groupIdsToRender.includes(groupId);
  }

  getClosestObject(site: Site) {
    let closestObject: {
      label: string;
      type: string;
      group: string;
      distance: number;
    } = null;
    let closestDistance = Infinity;

    this.spatialData.forEach((group) => {
      if (group.spatialDataObjects && group.spatialDataObjects.length) {
        group.spatialDataObjects.forEach((obj) => {
          try {
            const locationGeoJSON = JSON.parse(obj.location);
            if (locationGeoJSON.type === 'Point') {
              const [lng, lat] = locationGeoJSON.coordinates;
              const distance = this.calculateDistance(site.latitude, site.longitude, lat, lng);

              if (distance < closestDistance) {
                closestDistance = distance;
                closestObject = {
                  label: obj.label,
                  type: obj.type || 'Unknown',
                  group: group.label,
                  distance: distance,
                };
              }
            }
          } catch (error) {
            console.error(`Error parsing location for object when calculating distance:`, error);
          }
        });
      }
    });
    return closestObject;
  }

  renderSpatialDataOnMap(): void {
    if (!this.map || !this.spatialData) return;

    // Clear existing layers
    Object.values(this.mapLayers).forEach((layer) => {
      this.map.removeLayer(layer);
    });
    this.mapLayers = {};

    // Render each spatial data group that should be displayed
    this.spatialData
      .filter((group) => this.groupIdsToRender.includes(group.id))
      .forEach((group) => {
        // Add polygon if it exists
        if (group.polygon) {
          try {
            const polygonGeoJSON = JSON.parse(group.polygon);
            const layer = L.geoJSON(polygonGeoJSON, {
              style: {
                color: this.getRandomColor(),
                weight: 2,
                opacity: 0.7,
              },
            }).bindPopup(`<b>${group.label}</b>`);

            layer.addTo(this.map);
            this.mapLayers[`polygon_${group.id}`] = layer;
          } catch (error) {
            console.error(`Error parsing polygon for group ${group.id}:`, error);
          }
        }

        // Add spatial data objects
        if (group.spatialDataObjects && group.spatialDataObjects.length) {
          group.spatialDataObjects.forEach((obj) => {
            try {
              const locationGeoJSON = JSON.parse(obj.location);
              if (locationGeoJSON.type === 'Point') {
                const [lng, lat] = locationGeoJSON.coordinates;
                const marker = L.marker([lat, lng]).bindPopup(
                  `<b>${obj.label}</b><br>Group: ${group.label}<br>Type: ${obj.type || 'Unknown'}`
                );

                marker.addTo(this.map);
                this.mapLayers[`point_${obj.id}`] = marker;
              } else {
                const layer = L.geoJSON(locationGeoJSON, {
                  style: {
                    color: this.getRandomColor(),
                    weight: 2,
                    opacity: 0.7,
                  },
                }).bindPopup(`<b>${obj.label}</b><br>Type: ${obj.type || 'Unknown'}`);

                layer.addTo(this.map);
                this.mapLayers[`geo_${obj.id}`] = layer;
              }
            } catch (error) {
              console.error(`Error parsing location for object ${obj.id}:`, error);
            }
          });
        }
      });

    if (this.renderPobVesselsInMap && this.sites) {
      // Filter sites with POB feature enabled and valid coordinates
      const pobVessels = this.sites.filter(
        (site) => site.featureFlags?.connectPOB && site.latitude != null && site.longitude != null
      );

      // Add vessel markers to the map
      pobVessels.forEach((vessel) => {
        try {
          // Find closest spatial object to this vessel
          let closestObject = this.getClosestObject(vessel);

          // Create a vessel marker using circleMarker instead of a custom icon
          const marker = L.circleMarker([vessel.latitude, vessel.longitude], {
            radius: 8,
            fillColor: '#aaa',
            color: '#fff',
            weight: 2,
            opacity: 1,
            fillOpacity: 0.8,
          }).bindPopup(`
            <b>${vessel.name || 'Unnamed Vessel'}</b><br>
            IMO: ${vessel.imoNumber || 'N/A'}<br>
            MMSI: ${vessel.mmsi || 'N/A'}<br>
            Status: ${vessel.state}<br>
            Last Position Update UTC: ${
              vessel.positionUpdateDateTime ? vessel.positionUpdateDateTime.toString().substring(0, 19) : ''
            }
            ${
              closestObject
                ? `<br><br><b>Closest Object:</b><br>${closestObject.label} (${closestObject.type})<br>in group ${
                    closestObject.group
                  }<br>Distance: ${closestObject.distance.toFixed(2)} km`
                : '<br><br>No nearby objects found'
            }
          `);

          marker.addTo(this.map);
          this.mapLayers[`vessel_${vessel.id}`] = marker;
        } catch (error) {
          console.error(`Error rendering vessel ${vessel.id}:`, error);
        }
      });
    }
  }

  /**
   * Calculate distance between two points using the Haversine formula
   * @param lat1 First point latitude
   * @param lon1 First point longitude
   * @param lat2 Second point latitude
   * @param lon2 Second point longitude
   * @returns Distance in kilometers
   */
  calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance;
  }

  /**
   * Convert degrees to radians
   */
  deg2rad(deg: number): number {
    return deg * (Math.PI / 180);
  }

  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  handleLabelUpdate(element: SpatialDataObjectGroupReadModel, event: string): void {
    element.label = event;
  }

  toggleRenderAllInMap(): void {
    if (this.groupIdsToRender.length === this.spatialData.length) {
      this.groupIdsToRender = [];
    } else {
      this.groupIdsToRender = this.spatialData.map((group) => group.id);
    }

    this.renderSpatialDataOnMap();
  }

  toggleRenderVesselsInMap(): void {
    this.renderPobVesselsInMap = !this.renderPobVesselsInMap;
    this.renderSpatialDataOnMap();
  }
}
