import { Search } from './hw.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { queryToParams } from '../../utils/queryToParams';

@Injectable()
export class CustomerService {
  createNewSiteOnCustomer(siteName: string, customerId: string): any {
    const newConfig = {
      customerId: customerId,
      siteName: siteName,
      siteConfig: { view: { sensors: [], baseLayers: [] } },
    };
    return this.httpClient.post(environment.apiAddress + '/customer/' + customerId + '/site', newConfig);
  }
  constructor(private httpClient: HttpClient) {}

  getCustomer(search?: Search): Observable<Customer[]> {
    const queryString = search ? queryToParams(search) : '';
    return this.httpClient.get<Customer[]>(environment.apiAddress + '/customer' + queryString);
  }
  getCustomerById(id: any): Observable<Customer> {
    return this.httpClient.get<Customer>(environment.apiAddress + '/customer/' + id);
  }
  createCustomer(name: string): Observable<Customer> {
    return this.httpClient.post<Customer>(environment.apiAddress + '/customer/', { name: name });
  }
  saveCustomer(customer: Customer): any {
    return this.httpClient.put(environment.apiAddress + '/customer/' + customer.id, customer);
  }
  deleteCustomer(customerId: string): any {
    return this.httpClient.delete(environment.apiAddress + '/customer/' + customerId);
  }
  deleteSiteConfig(siteConfigId: any): any {
    throw new Error('Method not implemented.');
  }
}

export interface CustomerSearch extends Search {
  name?: string;
  id?: string;
}

export interface Site {
  id: string;
  name: string;
}

export interface Customer {
  name: string;
  sites: Site[];
  id: string;
  type: string;
  created: Date;
  contact: Contact[];
}

export interface Contact {
  name: string;
  email: string;
  phone: string;
  position: string;
}
