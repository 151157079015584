<h1>Hardware</h1>

<button mat-raised-button (click)="onNewHardwareClick()">Add HardwareDevices</button>
<button mat-raised-button (click)="onBulkAddEventClick()" [disabled]="selectedHardware.length === 0">
  Bulk add Event to {{ selectedHardware.length }} selected devices.
</button>
<div>
  <h2>Query</h2>
  <app-hardware-search-field (onSubmit)="queryHardwareFormSubmit($event)"></app-hardware-search-field>
</div>

<mat-tab-group>
  <mat-tab label="Tags and nodes">
    <app-hardware-table
      *ngIf="hardwareDevices; let hardwareDevices; else: loading"
      [(orderBy)]="queryForm.OrderBy"
      [(orderDirection)]="queryForm.OrderDirection"
      [(limit)]="queryForm.Limit"
      [(page)]="queryForm.Page"
      [hardwareDevices]="hardwareDevices"
      [displayedColumns]="displayedColumns"
      [(selectedHardware)]="selectedHardware"
      (change)="getHardwareDevices()"
    ></app-hardware-table>
  </mat-tab>
</mat-tab-group>

<ng-template #loading>
  <div class="loading-indicator">
    <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
  </div>
</ng-template>

<router-outlet></router-outlet>
