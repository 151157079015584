<div (click)="onCloseClick()" class="modalBackground">
  <!-- background is used to detect clicks outside of modal -->
</div>
<mat-card appearance="outlined" id="hardwaredetails">
  <mat-card-header>
    <button mat-button (click)="onCloseClick()">Close</button>
  </mat-card-header>

  <mat-divider></mat-divider>
  <br />

  <div *ngIf="hardware; let hardware; else: loading">
    <form (ngSubmit)="onSaveClick()">
      <mat-form-field style="width: 300px">
        <input matInput readonly disabled [ngModel]="hardware.id" name="Id" placeholder="Id" />
      </mat-form-field>
      <mat-form-field style="width: 200px">
        <input matInput [(ngModel)]="hardware.serial" name="Serial" placeholder="Serial number" />
      </mat-form-field>
      <mat-form-field style="width: 300px">
        <input matInput [(ngModel)]="hardware.macAddress" name="MacAddress" placeholder="MAC-Address" />
      </mat-form-field>
      <mat-form-field style="width: 300px">
        <input matInput [(ngModel)]="hardware.bleMacAddress" name="bleMacAddress" placeholder="BLE-MAC-Address" />
      </mat-form-field>
      <mat-form-field style="width: 100px">
        <input
          matInput
          readonly
          disabled
          [ngModel]="hardware.deviceType"
          name="DeviceType"
          type="text"
          placeholder="DeviceType"
        />
      </mat-form-field>
      <mat-form-field style="width: 200px">
        <input
          matInput
          readonly
          disabled
          [ngModel]="hardware.lastObserved | date: 'yyyy-MM-dd - HH:mm:ss'"
          name="LastObserved"
          placeholder="LastObserved"
        />
      </mat-form-field>
      <button mat-raised-button type="submit">Save</button>
    </form>
    <span
      >Connected site:
      <button mat-button [routerLink]="['/customer/' + hardware.site?.customer?.id + '/site/' + hardware.site?.id]">
        {{ hardware.site?.name }}
      </button></span
    >
    <br />
    <button mat-raised-button color="warn" (click)="deleteHardware()">Delete hardware</button>

    <mat-expansion-panel (opened)="getHwDiagnostic()">
      <mat-expansion-panel-header>
        <mat-panel-title> Last Hardware Diagnostic </mat-panel-title>
      </mat-expansion-panel-header>

      <div *ngIf="hwDiagnostic; let hwDiagnostic; else: loading">
        <pre>{{ hwDiagnostic | json }}</pre>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="edgeDevice">
      <mat-expansion-panel-header>
        <mat-panel-title> Azure AD and IoT-Hub connection data: </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <mat-form-field style="width: 300px">
          <input
            matInput
            readonly
            disabled
            [ngModel]="edgeDevice.azureIotHubHostName"
            name="azureIotHubHostName"
            type="text"
            placeholder="AzureIotHubHostname"
          />
        </mat-form-field>
        <mat-form-field style="width: 300px">
          <input
            matInput
            readonly
            disabled
            [ngModel]="edgeDevice.azureIotEdgeDeviceId"
            name="azureIotEdgeDeviceId"
            type="text"
            placeholder="AzureIotEdgeDeviceId"
          />
        </mat-form-field>
        <mat-form-field style="width: 300px">
          <input
            matInput
            readonly
            disabled
            [ngModel]="edgeDevice.authApplicationId"
            name="authApplicationId"
            type="text"
            placeholder="authApplicationId"
          />
        </mat-form-field>
        <mat-form-field style="width: 300px">
          <input
            matInput
            readonly
            disabled
            [ngModel]="edgeDevice.authTenantId"
            name="authTenantId"
            type="text"
            placeholder="authTenantId"
          />
        </mat-form-field>
        <mat-form-field style="width: 200px">
          <input matInput [(ngModel)]="edgeDevice.vpnName" name="vpnName" placeholder="vpnName" />
        </mat-form-field>
        <mat-form-field style="width: 200px">
          <input matInput [(ngModel)]="edgeDevice.vpnPort" name="vpnPort" placeholder="vpnPort" />
        </mat-form-field>
        <button mat-raised-button (click)="saveEdgeDevice()">Save edgeDevice vpn fields</button>
        <button mat-raised-button (click)="connectToIotEdgeDevice()">Get IotHubEdgeDevice ConnectionString</button>
        <button mat-raised-button (click)="connectToAzureAd()">Connect to AzureAd or regenerate keys</button>
        <br />
        <span *ngIf="iotEdgeDeviceConnectionString"
          >IotHub EdgeDevice Connection String: {{ iotEdgeDeviceConnectionString }}</span
        >
      </div>
    </mat-expansion-panel>

    <br />
    <div *ngIf="edgeDevice">
      <span>Get logs from device: </span>
      <mat-form-field style="width: 300px">
        <input
          matInput
          [(ngModel)]="uploadEdgeDeviceLogsPayload.applicationName"
          name="applicationName"
          type="text"
          placeholder="ApplicationName / Module Name"
        />
      </mat-form-field>
      <mat-form-field style="width: 300px">
        <input
          matInput
          [(ngModel)]="uploadEdgeDeviceLogsPayload.numberOfFilesPrApplication"
          name="numberOfFilesPrApplication"
          type="number"
          placeholder="NumberOfFilesPrApplication"
        />
      </mat-form-field>
      <button mat-raised-button (click)="uploadEdgeDeviceLogs()" *ngIf="!edgeDeviceUploadLogsLoading; else loading">
        Upload logs to blob storage
      </button>
      <br />
      <span *ngIf="!edgeDeviceUploadLogsLoading">
        {{ edgeDeviceUploadLogsResponse }}
      </span>
    </div>

    <mat-divider></mat-divider>

    <!-- Add new event -->
    <div>
      <h2>Events</h2>
      <button mat-button (click)="getEvents()">Get events</button>
      <br />
      <mat-form-field>
        <mat-select [(ngModel)]="newEventData.hardwareEventTypeId" placeholder="Event Type">
          <mat-option *ngFor="let eventType of hardwareEventTypes" [value]="eventType.id">
            {{ eventType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field style="width: 400px">
        <input matInput type="text" [(ngModel)]="newEventData.comment" placeholder="comment" />
      </mat-form-field>
      <button mat-button (click)="onNewEventSave()">Save event</button>
      <br />

      <!-- List of events -->
      <mat-paginator
        [(pageSize)]="queryEvents.Limit"
        [pageIndex]="queryEvents.Page"
        [pageSizeOptions]="[3, 5, 10]"
        length="100"
        (page)="onPageChange($event)"
      ></mat-paginator>

      <mat-table
        #table
        matSort
        (matSortChange)="onSortChange($event)"
        [dataSource]="eventsDataSource"
        style="overflow-y: scroll; max-height: 100%"
      >
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Id </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <mat-header-cell *matHeaderCellDef mat-sort-header> createdAt </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.createdAt | date: 'yyyy-MM-dd HH:mm:ss' }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="hardwareEventType">
          <mat-header-cell *matHeaderCellDef mat-sort-header> hardwareEventType </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.hardwareEventType.name }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="comment">
          <mat-header-cell *matHeaderCellDef> comment </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.comment }} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>

  <ng-template #loading>
    <div class="loading-indicator">
      <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </div>
  </ng-template>
</mat-card>
