import { Component, OnInit, Inject } from '@angular/core';
import { CustomerService, Customer, Site } from '../services/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteService } from '../services/site.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-customerdetails',
  templateUrl: './customerdetails.component.html',
  styleUrls: ['./customerdetails.component.css'],
})
export class CustomerdetailsComponent implements OnInit {
  constructor(
    private customerService: CustomerService,
    private siteConfigService: SiteService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {}
  step = 0;
  customer: Customer;
  createEnabled = false;
  appId: any = '';
  displayedColumns = ['name', 'id', 'state', 'delete'];
  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('customerId');
    this.customerService.getCustomerById(id).subscribe((s) => {
      this.customer = s;
    });
  }

  newSite() {
    this.openDialog();
  }
  saveCustomer() {
    this.customerService.saveCustomer(this.customer).subscribe((s) => {
      this.snackBar.open('Customer saved', null, { duration: 2000 });
    });
  }
  deleteCustomer() {
    this.customerService.deleteCustomer(this.customer.id).subscribe((s) => {
      this.router.navigateByUrl('/customer');
    });
  }

  deleteSite(site: Site) {
    // Remove from array
    // this.customer.sites.splice(this.customer.sites.findIndex(s => s.id === siteConfig.id), 1);
    // delete siteconfig
    this.siteConfigService.deleteSite(site.id).subscribe((s) => {
      console.log(s);
      this.snackBar.open(`Site ${site.name} deleted and it's sharedConfigEvents. Did ${s} changes`, null, {
        duration: 8000,
      });
      this.customer.sites.splice(
        this.customer.sites.findIndex((s) => s.id === site.id),
        1
      );
    });
    // Save customer
    // this.saveCustomer();
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(GetAppIdDialogComponent, {
      width: '400px',
      data: { appId: this.appId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.siteName) {
        this.customerService.createNewSiteOnCustomer(result.siteName, this.customer.id).subscribe((s) => {
          console.log(s);
          this.router.navigateByUrl(`customer/${this.customer.id}/site/${s.id}/details`);
        });
      }
    });
  }
}

@Component({
  selector: 'azure-ad-appid-dialog',
  template: `<mat-card appearance="outlined">
    <mat-form-field style="width:300px;">
      <input matInput [(ngModel)]="data.siteName" placeholder="Enter sitename" />
    </mat-form-field>
    <mat-card-actions>
      <button mat-button (click)="onSaveClick()">Create</button>
      <button mat-button (click)="onCancelClick()">Cancel</button>
    </mat-card-actions>
  </mat-card> `,
})
export class GetAppIdDialogComponent {
  constructor(public dialogRef: MatDialogRef<GetAppIdDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onSaveClick(): void {
    this.dialogRef.close(this.data);
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
}
