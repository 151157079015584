import { EdgeDevice } from './../services/site.service';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  HardwareService,
  HardwareDevice,
  HardwareEvent,
  HardwareEventType,
  HardwareDeviceEventSearch,
  HwDiagnostic,
} from '../services/hw.service';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { EdgeDeviceService } from '../services/edgeDevice.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-hardwaredetails',
    templateUrl: './hardwaredetails.component.html',
    styleUrls: ['./hardwaredetails.component.css'],
    standalone: false
})
export class HardwareDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public snackBar: MatSnackBar,
    private hwService: HardwareService,
    private route: ActivatedRoute,
    private router: Router,
    private edgeDeviceService: EdgeDeviceService,
    public dialog: MatDialog
  ) {
    this.route.params.subscribe((params) => (this.hardwareDeviceId = params.id));
  }
  public hardwareDeviceId: string;
  public hardware: HardwareDevice;
  public edgeDevice: EdgeDevice;
  public iotEdgeDeviceConnectionString: string;
  public edgeDeviceUploadLogsLoading = false;
  public edgeDeviceUploadLogsResponse: string;
  public uploadEdgeDeviceLogsPayload = {
    numberOfFilesPrApplication: 3,
    applicationName: '',
  };
  public hwDiagnostic: HwDiagnostic[];

  public displayedColumns = ['id', 'createdAt', 'hardwareEventType', 'comment'];
  public newEventData: HardwareEvent = {
    hardwareDeviceId: '',
    comment: '',
    hardwareEventTypeId: null,
  };
  public events: Observable<HardwareEvent[]>;
  public hardwareEventTypes: Array<HardwareEventType>;
  public eventsDataSource = new MatTableDataSource<HardwareEvent>();
  public queryEvents: HardwareDeviceEventSearch = {
    Page: 0,
    Limit: 5,
    OrderBy: 'CreatedAt',
    OrderDirection: 'desc',
  };

  ngOnInit(): void {
    // Get hardwareDevice data
    this.hwService.getHardware(this.hardwareDeviceId).subscribe((h) => {
      this.hardware = h;
      console.log(h);

      if (this.hardware.deviceType === 'SRT-ED') {
        this.getEdgeDeviceData();
      }
    });
    // Get hardwareDeviceEventTypes for adding new events
    this.hwService.getHardwareEventTypes().subscribe((eventTypes) => {
      this.hardwareEventTypes = eventTypes as HardwareEventType[];
    });

    // Get hardwareDeviceEvents
    this.getEvents();
  }

  getEdgeDeviceData() {
    this.edgeDeviceService.getById(this.hardware.id).subscribe((ed) => {
      this.edgeDevice = ed;
    });
  }

  ngAfterViewInit(): void {
    this.eventsDataSource.sort = this.sort;
    this.eventsDataSource.paginator = this.paginator;
  }

  onNewEventSave(): void {
    this.newEventData.hardwareDeviceId = this.hardwareDeviceId;
    this.hwService.saveHardwareEvent(this.newEventData).subscribe(
      (s) => {
        this.getEvents();
        this.snackBar.open('Event saved', null, { duration: 3000 });
      },
      (error) => {
        this.snackBar.open('Error: ' + error.error.id[0], null, { duration: 4000 });
      }
    );
  }

  /**
   * Get events for hardwareDeviceId
   */
  getEvents(): void {
    this.hwService.getHardwareEvent(this.hardwareDeviceId, this.queryEvents).subscribe((events) => {
      this.eventsDataSource.data = events as HardwareEvent[];
    });
  }

  getHwDiagnostic() {
    // Get HardwareDeviceDiagnostic
    if (!this.hwDiagnostic) {
      this.hwService.getHwDiagnostic(this.hardwareDeviceId).subscribe(
        (hwd) => {
          this.hwDiagnostic = hwd;
        },
        (error) => {
          this.hwDiagnostic = [error];
        }
      );
    }
  }

  saveEdgeDevice() {
    this.edgeDeviceService.putById(this.edgeDevice).subscribe(
      (s) => {
        this.snackBar.open('EdgeDevice saved', null, { duration: 3000 });
      },
      (error) => {
        this.snackBar.open('Error: ' + error.error.id[0], null, { duration: 4000 });
      }
    );
  }

  onSaveClick(): void {
    // TODO: Update hardwareOverviewComponentTable
    this.saveHardwareDetails(this.hardware);
  }

  onCloseClick(): void {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  saveHardwareDetails(hardware) {
    this.hwService.saveHardware(hardware).subscribe(
      (s) => {
        this.snackBar.open('Hardware saved', null, { duration: 3000 });
      },
      (error) => {
        this.snackBar.open('Error: ' + error.error.id[0], null, { duration: 4000 });
      }
    );
  }

  onPageChange(event?: PageEvent) {
    this.queryEvents.Page = event.pageIndex;
    this.queryEvents.Limit = event.pageSize;
    this.getEvents();
  }

  onSortChange(event: Sort) {
    this.queryEvents.OrderBy = event.active;
    this.queryEvents.OrderDirection = event.direction;
    this.getEvents();
  }

  connectToIotEdgeDevice() {
    if (this.edgeDevice) {
      this.edgeDeviceService.connectToIotHubEdgeDevice(this.hardware.id).subscribe(
        (s) => {
          this.iotEdgeDeviceConnectionString = s;
          this.getEdgeDeviceData();
        },
        (error) => {
          console.error(error);
          this.snackBar.open('Error: ' + JSON.stringify(error), null, { duration: 10000 });
        }
      );
    }
  }
  connectToAzureAd() {
    if (this.edgeDevice) {
      this.snackBar.open('Starting to connect to AzureAd', null, { duration: 3000 });
      this.edgeDeviceService.connectToAzureAd(this.hardware.id).subscribe(
        (s) => {
          this.snackBar.open(`Success: ${s}`, null, { duration: 8000 });
        },
        (error) => {
          console.error(error);
          this.snackBar.open('Error: ' + JSON.stringify(error.error), null, { duration: 10000 });
        }
      );
    }
  }

  uploadEdgeDeviceLogs() {
    if (this.edgeDevice) {
      this.edgeDeviceUploadLogsLoading = true;
      this.edgeDeviceService.uploadDeviceLogs(this.edgeDevice.id, this.uploadEdgeDeviceLogsPayload).subscribe(
        (s) => {
          console.log(s);
          this.edgeDeviceUploadLogsResponse = s;
          this.edgeDeviceUploadLogsLoading = false;
        },
        (error) => {
          console.log(error);
          this.edgeDeviceUploadLogsResponse = error.error;
          this.edgeDeviceUploadLogsLoading = false;
        }
      );
    }
  }

  deleteHardware() {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
    confirmDialogRef.afterClosed().subscribe((confirm: Boolean) => {
      if (confirm) {
        if (this.edgeDevice) {
          this.edgeDeviceService.deleteById(this.edgeDevice.id).subscribe(
            (s) => {
              this.snackBar.open('Successfully deleted hardware', null, { duration: 5000 });
              this.router.navigate(['../'], { relativeTo: this.route });
            },
            (error) => {
              this.snackBar.open('Error: ' + error.error.id[0], null, { duration: 4000 });
            }
          );
        } else {
          this.hwService.deleteHardware(this.hardware.id).subscribe(
            (s) => {
              this.snackBar.open('Successfully deleted hardware', null, { duration: 5000 });
              this.router.navigate(['../'], { relativeTo: this.route });
            },
            (error) => {
              this.snackBar.open('Error: ' + error.error.id[0], null, { duration: 4000 });
            }
          );
        }
      }
    });
  }
}
