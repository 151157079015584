<form (ngSubmit)="parseSearchBox()">
  <mat-form-field style="width: 80%">
    <input
      matInput
      [(ngModel)]="searchBox"
      name="search"
      type="text"
      placeholder="Ex: id:Id;loc:Lager;Event:hw-test;NotEvent:fw-test;Comment:OK;serial:234-123;mac:aaa-bbb;bleMac:aaa-bbb;deviceType:SRT-MN;startSerial:fullSerial;stopSerial:fullSerial;"
    />
  </mat-form-field>
  <button
    mat-raised-button
    type="submit"
    matTooltip="
        Write without spaces. Implemented:
        id:GuId;
        loc:text;
        Event:name;
        NotEvent:name;
        Comment:text;
        DeviceType:TypeName;
        Serial:serial;
        Mac:mac;
        bleMac:bleMac;
        startSerial:fullSerial;
        stopSerial:fullSerial;
      "
  >
    Search
  </button>
</form>
