<button mat-raised-button (click)="onNewSensorClick()">Add nodes</button>
<button mat-raised-button (click)="onConnectNodesByMac()">Add nodes based on mac</button>
<button mat-raised-button (click)="onSelectNodesByMac()">Select nodes based on list of macs</button>
<button mat-button (click)="onDeleteSelectedClick()" color="warn">Remove {{ selected.selected.length }} nodes.</button>
<span>{{ nodes.length }} nodes</span>
<mat-table #sensorTable [dataSource]="nodes">
  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef class="w-64">
      <mat-checkbox
        (change)="$event ? masterToggle() : null"
        [checked]="selected.hasValue() && isAllSelected()"
        [indeterminate]="selected.hasValue() && !isAllSelected()"
        class="w-64"
      >
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let row" class="w-64">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? selected.toggle(row) : null"
        [checked]="selected.isSelected(row)"
      >
      </mat-checkbox>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
    <mat-cell *matCellDef="let element"
      ><button mat-button [routerLink]="[element.hardwareDevice.id]">{{ element.hardwareDevice.id }}</button></mat-cell
    >
  </ng-container>
  <ng-container matColumnDef="macAddress">
    <mat-header-cell *matHeaderCellDef> MacAddress </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.hardwareDevice.macAddress }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="lastObserved">
    <mat-header-cell *matHeaderCellDef> Last Observed </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.hardwareDevice.lastObserved }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="x">
    <mat-header-cell *matHeaderCellDef class="w-64"> X </mat-header-cell>
    <mat-cell *matCellDef="let element" class="w-64"> {{ element.x }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="y">
    <mat-header-cell *matHeaderCellDef class="w-64"> Y </mat-header-cell>
    <mat-cell *matCellDef="let element" class="w-64"> {{ element.y }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="options">
    <mat-header-cell *matHeaderCellDef class="w-150"> Options </mat-header-cell>
    <mat-cell *matCellDef="let element" class="w-150">
      <button mat-button color="warn" (click)="deleteSensor(element)">Disconnect</button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumnsSensors"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumnsSensors"></mat-row>
</mat-table>
