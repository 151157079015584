/**
 * Convert object with query params to a queryString
 * @param search Object with search params
 */
export function queryToParams(search: Object): string {
  const params: Array<String> = [];
  for (const prop in search) {
    if (search.hasOwnProperty(prop) && search[prop]) {
      params.push(encodeURIComponent(prop) + '=' + encodeURIComponent(search[prop]));
    }
  }
  let queryString = '';
  if (params.length > 0) {
    queryString = '?' + params.join('&');
  }

  return queryString;
}
