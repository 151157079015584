import { Component, Output, EventEmitter } from '@angular/core';
import { HardwareDeviceSearch } from '../services/hw.service';

/**
 * General component for HardwareDeviceQueryField
 * Usage: `<app-hardware-search-field (onSubmit)="getHardwareDevices($event)"></app-hardware-search-field>`
 * Where `$event` is a { @link HardwareDeviceSearch }
 * @export HardwareSearchFieldComponent
 * @class HardwareSearchFieldComponent
 * @see HardwareDeviceSearch for `$event` reference
 */
@Component({
    selector: 'app-hardware-search-field',
    templateUrl: './hardware-search-field.component.html',
    styleUrls: ['./hardware-search-field.component.css'],
    standalone: false
})
export class HardwareSearchFieldComponent {
  constructor() {}
  @Output() onSubmit: EventEmitter<HardwareDeviceSearch> = new EventEmitter();

  public searchBox = '';

  parseSearchBox() {
    const search: HardwareDeviceSearch = {};
    const searchParameters = this.searchBox.split(';');
    searchParameters.forEach((q) => {
      const values = q.split(/:(.+)/);
      switch (values[0].toLowerCase()) {
        case 'loc':
          search.Loc = values[1];
          break;
        case 'id':
          search.Id = values[1];
          break;
        case 'currloc':
          search.Currloc = values[1];
          break;
        case 'serial':
          search.Serial = values[1];
          break;
        case 'startserial':
          search.StartSerial = values[1];
          break;
        case 'stopserial':
          search.StopSerial = values[1];
          break;
        case 'mac':
          search.MacAddress = values[1];
          break;
        case 'blemac':
          search.BLEMacAddress = values[1];
          break;
        case 'devicetype':
          search.DeviceType = values[1];
          break;
        case 'comment':
          search.Comment = values[1];
          break;
        case 'event':
          search.Event = values[1];
          break;
        case 'notevent':
          search.NotEvent = values[1];
          break;
        case 'orderBy':
          search.OrderBy = values[1];
          break;
        case 'orderDirection':
          search.OrderDirection = values[1];
          break;
        case 'limit':
          search.Limit = Number(values[1]);
          break;
        default:
          break;
      }
    });
    this.onSubmit.emit(search);
  }
}
