import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class SensorTypeService {
  constructor(private httpClient: HttpClient) {}

  getSensorTypes(): Observable<SensorTypeDefinition[]> {
    return this.httpClient.get<SensorTypeDefinition[]>(environment.apiAddress + '/sensorType');
  }

  addSensorTypes(modbusConfiguration: string): Observable<any> {
    return this.httpClient.post<SensorTypeDefinition>(
      `${environment.apiAddress}/sensorType/modbusConfiguration`,
      JSON.parse(modbusConfiguration)
    );
  }

  deleteSensorTypeDefinition(sensorTypeId: string): any {
    throw new Error('Method not implemented.');
  }
}

export interface SensorTypeDefinition {
  id: string;
  name: string;
  sensorTypeId: number;
  revision: number;
  description?: string | null;
  measurementDefinitions: string;
  statusMapping?: string | null;
  modbusDeviceType?: ModbusDeviceType | null;
  sourceJson?: string | null;
}

export interface ModbusDeviceType {
  id: string;
  name: string;
  modbusType: string;
  typeId: number;
  revision: number;
}
