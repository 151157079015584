<div *ngIf="customer; let customer; else: loading">
  <mat-grid-list cols="2" rowHeight="200px">
    <mat-grid-tile>
      <h1>Customer: {{ customer.name }}</h1>
    </mat-grid-tile>
    <mat-grid-tile>
      <h4>Contact info:</h4>
      <span *ngIf="customer.contact">
        <b>Name: </b> <span [ngModel]="customer.contact[0].name"></span> <b>Phone: </b>
        <span [ngModel]="customer.contact[0].phone"></span> <b>Email: </b>
        <span [ngModel]="customer.contact[0].email"></span> <b>Position: </b>
        <span [ngModel]="customer.contact[0].position"></span>
      </span>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-tab-group [selectedIndex]="1">
    <mat-tab label="Details">
      <button mat-raised-button color="primary" (click)="saveCustomer()">Save</button>
      <button mat-button color="warn" (click)="deleteCustomer()">Delete</button>
      <div class="inputcontainer">
        <mat-form-field>
          <input matInput disabled type="string" min="1" placeholder="Id" [(ngModel)]="customer.id" />
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="Name" [(ngModel)]="customer.name" />
        </mat-form-field>
      </div>
    </mat-tab>
    <mat-tab label="Sites" selected>
      <button mat-raised-button color="primary" (click)="newSite()">Create site</button>
      <mat-table #table [dataSource]="customer.sites">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="state">
          <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.state }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef> Delete </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="deleteSite(element)">
              <mat-icon class="mat-24">delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          [routerLink]="['site/' + row.id + '/details']"
          style="cursor: pointer"
        ></mat-row>
      </mat-table>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #loading>
  <div class="loading-indicator">
    <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
  </div>
</ng-template>
