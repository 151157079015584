// All env variables with prefix `APP_` added to the docker container when starting it
// will be added to window.env by `docker-env-to-js-script.sh` script.

declare global {
  interface Window {
    env:
      | {
          APP_API_URL?: string;
          APP_APP_INSIGHTS_KEY?: string;
          APP_BUILD?: string;
          APP_BUILD_DATE?: string;
          APP_VERSION?: string;
          APP_AUTHENTICATION_AUTHORITY?: string;
          APP_AUTHENTICATION_TENANTID?: string;
          APP_AUTHENTICATION_CLIENTID?: string;
          APP_AUTHENTICATION_REDIRECTURI?: string;
          APP_AUTHENTICATION_RESOURCE?: string;
        }
      | undefined;
  }
}

let defaultEnvironment = {
  apiAddress: 'https://edgeapi.scanreach.com/api',
  authentication: {
    authority: 'https://login.microsoftonline.com',
    tenantId: '5f92765f-23af-4383-8724-16b0db42ffd7',
    clientId: '321cb17d-f1e2-4c0c-8976-f844a1915d40',
    redirectUri: 'https://admin.scanreach.com',
    resource: 'api://66e77b18-c959-4e4a-8d81-9ff9264d4c44',
  },
};

export const environment = {
  production: false,
  get apiAddress() {
    return window.env?.APP_API_URL || defaultEnvironment.apiAddress;
  },
  authentication: {
    get authority() {
      return window.env?.APP_AUTHENTICATION_AUTHORITY || defaultEnvironment.authentication.authority;
    },
    get tenantId() {
      return window.env?.APP_AUTHENTICATION_TENANTID || defaultEnvironment.authentication.tenantId;
    },
    get clientId() {
      return window.env?.APP_AUTHENTICATION_CLIENTID || defaultEnvironment.authentication.clientId;
    },
    get redirectUri() {
      return window.env?.APP_AUTHENTICATION_REDIRECTURI || defaultEnvironment.authentication.redirectUri;
    },
    get resource() {
      return window.env?.APP_AUTHENTICATION_RESOURCE || defaultEnvironment.authentication.resource;
    },
  },
};

console.dir(environment);

if (process.env.NODE_ENV == 'development') {
  defaultEnvironment = {
    ...defaultEnvironment,
    apiAddress: 'https://edgeapi.staging.scanreach.com/api',
    // apiAddress: 'http://localhost:5002/api',
    authentication: {
      ...defaultEnvironment.authentication,
      redirectUri: 'http://localhost:4202',
      authority: 'https://login.microsoftonline.com',
      tenantId: '7a9e890e-9ba5-4727-9002-6c84d081260d',
      clientId: 'a0e6f863-5aa5-41d0-a583-aa4d2f9c9a71',
      resource: 'api://aad-scanreachcloudapi-staging',
    },
  };
}
