import { SelectionModel } from '@angular/cdk/collections';
import { HardwareDevice } from './../services/hw.service';
import { Component, OnInit, ViewChild, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-hardware-table',
  templateUrl: './hardware-table.component.html',
  styleUrls: ['./hardware-table.component.css'],
})
export class HardwareTableComponent implements OnInit, OnChanges {
  constructor() {}
  @Input() limit: Number;
  @Output() limitChange = new EventEmitter<Number>();
  @Input() page: Number;
  @Output() pageChange = new EventEmitter<Number>();
  @Input() orderBy: Number;
  @Output() orderByChange = new EventEmitter<String>();
  @Input() orderDirection: Number;
  @Output() orderDirectionChange = new EventEmitter<String>();

  @Input() selectedHardware: HardwareDevice[];
  @Output() selectedHardwareChange: EventEmitter<HardwareDevice[]> = new EventEmitter();

  @Input() hardwareDevices: HardwareDevice[];
  @Output() change = new EventEmitter();

  @Input() displayedColumns: string[] = [];

  @ViewChild(MatSort) sort: MatSort;

  public hardwareDevicesDataSource = new MatTableDataSource<HardwareDevice>();
  public selected = new SelectionModel<HardwareDevice>(true, []);

  isAllSelected() {
    const numSelected = this.selected.selected.length;
    const numRows = this.hardwareDevicesDataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    if (this.isAllSelected()) {
      this.selected.clear();
    } else {
      this.hardwareDevicesDataSource.data.forEach((hd) => this.selected.select(hd));
    }
  }

  onPageChange(event: PageEvent) {
    this.pageChange.emit(event.pageIndex);
    this.limitChange.emit(event.pageSize);
    this.change.emit();
  }

  onSortChange(event: Sort) {
    this.orderByChange.emit(event.active);
    this.orderDirectionChange.emit(event.direction);
    this.change.emit();
  }

  ngOnInit() {
    this.hardwareDevicesDataSource.data = this.hardwareDevices;
    this.selected.changed.subscribe((s) => {
      this.selectedHardwareChange.emit(this.selected.selected);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hardwareDevices']) {
      this.hardwareDevicesDataSource.data = this.hardwareDevices;
    }
  }
}
