import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-pagenotfound',
    templateUrl: './pagenotfound.component.html',
    styleUrls: ['./pagenotfound.component.css'],
    standalone: false
})
export class PagenotfoundComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
