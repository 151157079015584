import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class SiteConfigService {
  constructor(private httpClient: HttpClient) {}
  private readonly apiAddress = environment.apiAddress + '/site/';

  uploadImage(siteId: string, imageReference: string, file: any): any {
    const formData = new FormData();
    formData.append('file', file);
    // post the form data to the url defined above and map the response.
    // Then subscribe to initiate the post. if you don't subscribe, angular wont post.
    return this.httpClient.post(this.apiAddress + siteId + `/siteconfig/layer/${imageReference}/uploadimage`, formData);
  }

  getLayerBlobSas(siteId: string, imageReference: any) {
    return this.httpClient.get(this.apiAddress + siteId + `/siteconfig/layer/${imageReference}/getblobsas`);
  }
}
