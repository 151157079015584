import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  title = 'app';
  loggedinUser: string = 'Not logged in...';
  loggedin: boolean;
  public isIframe: boolean;
  constructor(private msalService: MsalService, private broadcastService: MsalBroadcastService) {
    const user = this.msalService.instance.getAllAccounts()[0];
    if (user) {
      this.loggedin = true;
      this.loggedinUser = user.name;
    } else {
      this.loggedin = false;
    }
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.broadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE))
      .subscribe((payload) => {
        console.log('login failure ' + JSON.stringify(payload));
        this.loggedin = false;
      });
    this.broadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe(() => {
        console.log('login success ');
        const user = this.msalService.instance.getAllAccounts()[0];
        this.loggedinUser = user.name;
        this.loggedin = true;
      });
  }
  signin() {
    this.msalService.loginRedirect();
  }
  signout() {
    console.log('signout');
    this.msalService.logout();
  }
  getToken() {}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
