import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SensorTypeDefinition, SensorTypeService } from '../services/sensorType.service';
import { Site, SiteService } from '../services/site.service';
@Component({
    selector: 'assign-sites-to-sensor-types-component',
    template: `
    <mat-card appearance="outlined">
      <p class="dialog-title" mat-dialog-title>
        <ng-container *ngIf="isStandalone; else notStandalone">
          Assign <b>{{ sensorType.name }}</b> (sensorTypeId: <b>{{ sensorType.sensorTypeId }}</b
          >, revision: <b>{{ sensorType.revision }}</b
          >) to Sites
        </ng-container>
        <ng-template #notStandalone> Select Sites that will get this SensorTypeDefinition </ng-template>
      </p>
      <mat-dialog-content>
        <form [formGroup]="form">
          <div style="display: flex; justify-content: space-between">
            <div style="flex: 1; margin-right: 10px">
              <label>SiteIds</label>
              <textarea
                #siteTextarea
                cols="20"
                rows="5"
                style="width: 100%"
                [value]="selectedSiteIds.join('
')"
                (input)="onTextareaInput($event)"
              ></textarea>
              <p *ngIf="warningMessage" style="color: red; white-space: pre-line;">{{ warningMessage }}</p>
            </div>
            <div style="flex: 1">
              <label>Select Sites</label>
              <mat-form-field appearance="fill" style="width: 100%">
                <mat-label>Site</mat-label>
                <mat-select formControlName="selectedSites" multiple (selectionChange)="onSelectionChange()">
                  <ngx-mat-select-search
                    [formControl]="siteFilterControl"
                    placeholderLabel="Type here to search"
                    noEntriesFoundLabel="'{{ siteFilterControl.value }}' not found"
                  ></ngx-mat-select-search>
                  <button mat-button (click)="toggleSelectAllForFilteredSites()">Select/Unselect filtered</button>
                  <mat-option *ngFor="let site of filteredSites" [value]="site.id">{{ site.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </form>
        <div>
          <p><b>If no sites are selected, the sensor type is available for all sites.</b></p>
          <p>
            <b>NOTE</b>: To mark the sensor type as not available on any site, assign it to
            <b>one of the ScanReach sites </b>.
          </p>
          <p>
            <b>NOTE</b>: When site IDs are assigned to a site, they will be downloaded to the gateway and
            <b>can trigger an update</b> of related SensorConfigurations. After you have assigned sites to the sensor
            type, it will <b>NOT</b> be possible to remove it from OnPrem using the admin tool.
          </p>
        </div>
        <button mat-raised-button color="primary" (click)="updateSiteIds()" *ngIf="isStandalone">
          Update Site IDs
        </button>
        <button mat-raised-button (click)="closeDialog()" *ngIf="isStandalone">Close</button>
      </mat-dialog-content>
    </mat-card>
  `,
    styles: [
        `
      .dialog-title {
        font-size: 20px;
      }
    `,
    ],
    standalone: false
})
export class AssignSitesToSensorTypesComponent implements OnInit {
  @Input() sensorType: SensorTypeDefinition;
  @Output() siteIdsChange = new EventEmitter<string[]>();
  // otherwise this component might affect other text boxes in the view
  @ViewChild('siteTextarea') siteTextarea: ElementRef;

  /** Fetched sites */
  sites: Site[] = [];
  // used while filtering -> list of sites that match the filter
  // should use filtering and searchable list, as list can be long
  filteredSites: Site[] = [];
  selectedSiteIds: string[] = [];
  form: FormGroup;

  siteFilterControl: FormControl = new FormControl();

  /** Shown under text box. Should contain info about siteIds that may be invalid or siteIds that are duplicatd */
  warningMessage: string = '';

  /** If this component is used as a standalone dialog or as part of another component (NewModbusView) */
  isStandalone: boolean;

  constructor(
    private fb: FormBuilder,
    private siteService: SiteService,
    private sensorTypeService: SensorTypeService,
    public snackBar: MatSnackBar,
    @Optional()
    // data will be injected here, if component is used as Dialog window
    // or it will be passed is Input() if used as a regular component. Then result can be emitted via Output()
    @Inject(MAT_DIALOG_DATA)
    public data: { sensorType: SensorTypeDefinition; isStandaloneDialog: boolean },
    @Optional() private dialogRef: MatDialogRef<AssignSitesToSensorTypesComponent>
  ) {
    // if we are in Dialog -> set sensorType and isStandalone from data
    // can not have Input and Inject named in the same way
    if (data) {
      this.sensorType = data.sensorType;
      this.isStandalone = data.isStandaloneDialog;
    }
  }

  ngOnInit(): void {
    this.fetchSites();
    this.selectedSiteIds = [...(this.sensorType.siteIds ?? [])];
    this.form = this.fb.group({
      selectedSites: [this.selectedSiteIds],
    });

    // when input in search area changes, filter sites
    this.siteFilterControl.valueChanges.subscribe(() => {
      this.filterSites();
    });

    // when selected sites change, update text area + emit event (when used as part of another component)
    this.form.get('selectedSites').valueChanges.subscribe(() => {
      this.selectedSiteIds = this.form.get('selectedSites').value;
      this.updateTextarea();
      this.siteIdsChange.emit(this.selectedSiteIds);
    });
  }

  fetchSites(): void {
    this.siteService.getSites().subscribe((sites) => {
      this.sites = sites;
      this.filteredSites = sites;
    });
  }

  filterSites(): void {
    const search = this.siteFilterControl.value ? this.siteFilterControl.value.toLowerCase() : '';
    this.filteredSites = this.sites.filter((site) => site.name.toLowerCase().includes(search));
  }

  onSelectionChange(): void {
    this.selectedSiteIds = this.form.get('selectedSites').value;
    this.updateTextarea();
    this.siteIdsChange.emit(this.selectedSiteIds);
  }

  // when input in text area changes:
  // try to produce warning -> if siteIds are invalid or duplicated
  // if siteIds can be found in list of Sites -> mark them as selected
  // emit event
  onTextareaInput(event: Event): void {
    const input = (event.target as HTMLTextAreaElement).value;
    const inputSiteIds = input.split('\n').map((id) => id.trim());
    const uniqueSiteIds = Array.from(new Set(inputSiteIds));
    const invalidSiteIds = uniqueSiteIds.filter((id) => !this.sites.some((site) => site.id === id));
    const duplicateSiteIds = inputSiteIds.filter((id, index) => inputSiteIds.indexOf(id) !== index);
    const duplicateSiteIdsSet = new Set(duplicateSiteIds);

    let warnings = [];
    if (invalidSiteIds.length > 0) {
      warnings.push(`The following site IDs are not found: ${invalidSiteIds.join(', ')}`);
    }
    if (duplicateSiteIdsSet.size > 0) {
      warnings.push(`The following site IDs are duplicated: ${Array.from(duplicateSiteIdsSet).join(', ')}`);
    }

    if (warnings.length > 0) {
      this.warningMessage = warnings.join('\n');
    } else {
      this.warningMessage = '';
    }

    this.selectedSiteIds = uniqueSiteIds.filter((id) => this.sites.some((site) => site.id === id));
    this.form.get('selectedSites').setValue(this.selectedSiteIds, { emitEvent: false });
    this.siteIdsChange.emit(this.selectedSiteIds);
  }

  updateTextarea(): void {
    if (this.siteTextarea) {
      this.siteTextarea.nativeElement.value = this.selectedSiteIds.join('\n');
    }
  }

  // for sites that are visible in the list -> toggle selection
  // Ocean ... -> select all Ocean * sites
  toggleSelectAllForFilteredSites(): void {
    const filteredSiteIds = this.filteredSites.map((site) => site.id);
    const allSelected = filteredSiteIds.every((id) => this.selectedSiteIds.includes(id));

    if (allSelected) {
      // Unselect all filtered sites
      this.selectedSiteIds = this.selectedSiteIds.filter((id) => !filteredSiteIds.includes(id));
    } else {
      // Select all filtered sites
      this.selectedSiteIds = Array.from(new Set([...this.selectedSiteIds, ...filteredSiteIds]));
    }

    this.form.get('selectedSites').setValue(this.selectedSiteIds);
    this.updateTextarea();
    this.siteIdsChange.emit(this.selectedSiteIds);
  }

  updateSiteIds(): void {
    this.sensorTypeService.updateAllowedSitesForSensorType(this.sensorType.id, this.selectedSiteIds).subscribe(
      (response) => {
        this.snackBar.open('Successfully updated site IDs', null, { duration: 3000 });
      },
      (error) => {
        this.snackBar.open(`Error: ${error.message}`, null, { duration: 3000 });
      }
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
