<div style="max-height: 100%;">

  <h1>SpatialData</h1>
  <div style="width: 100%;">
    <h2>Add new</h2>
    <form (submit)="addSpatialDataGroup()">
      <mat-form-field>
        <label for="label">Label:</label>
        <input matInput placeholder="Label" required name="label" [(ngModel)]="newSpatialDataGroup.label" />
      </mat-form-field>
      <mat-form-field>
        <label for="sites">SiteIds (1/line), empty for all:</label>
        <textarea matInput placeholder="SiteIds (1/line)" cols="40" name="sites"
          [(ngModel)]="newSpatialDataGroup.sites"></textarea>
      </mat-form-field>
      <br>
      <mat-form-field>
        <label for="spatialDataObject">SpatialDataObjects:</label>
        <textarea matInput placeholder="SpatialDataObjects" cols="80" rows="10" name="spatialDataObjects"
          [(ngModel)]="newSpatialDataGroup.spatialDataObjects"></textarea>
      </mat-form-field>
      <br>
      <button mat-raised-button color="primary" type="submit">Create new SpatialDataGroup</button>
    </form>
  </div>
  <br />
  <hr />

  <div style="display: grid; grid-template-columns: 1fr 1fr; height: 100%; overflow-y: auto;">
    <div *ngIf="spatialData; let spatialData; else: loading"
      style="height: 100%; overflow-y: scroll; width: 100%; max-width: fit-content">
      <br />
      <div style="display: inline-flex; gap: 12px;">
        <mat-slide-toggle [checked]="groupIdsToRender.length == spatialData.length" (change)="toggleRenderAllInMap()">
          Render
          all in map </mat-slide-toggle>

        <mat-slide-toggle [checked]="renderPobVesselsInMap" (change)="toggleRenderVesselsInMap()"> Render POB
          vessels </mat-slide-toggle>
      </div>
      <br />
      <br />
      <table mat-table [dataSource]="spatialDataTableSource" matSort class="mat-elevation-z8" style="width: 100%"
        aria-label="All spatialDataGroups">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
          <td mat-cell *matCellDef="let element">
            <a mat-stroked-button raised [routerLink]="['/SpatialData/' + element.id]">{{ element.id }}</a>
          </td>
        </ng-container>
        <ng-container matColumnDef="label">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Label</th>
          <td mat-cell *matCellDef="let element">
            <p *ngIf="element.id != selectedSpatialDataGroupId">{{ element.label }}</p>
            <input matInput placeholder="Label" *ngIf="element.id == selectedSpatialDataGroupId"
              [(ngModel)]="element.label" placeholder="Label" (ngModelChange)="handleLabelUpdate(element, $event)" />
          </td>
        </ng-container>
        <ng-container matColumnDef="polygon">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Polygon</th>
          <td mat-cell *matCellDef="let element">
            {{ element.polygon ? "Yes" : "No" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="spatialDataObjects">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>SpatialDataObjects</th>
          <td mat-cell *matCellDef="let element">
            <p *ngIf="element.id != selectedSpatialDataGroupId">{{ element.spatialDataObjects.length }}</p>
            <textarea *ngIf="element.id == selectedSpatialDataGroupId" cols="80" rows="40" style="width: 100%"
              [value]="element.spatialDataObjects | json"
              (input)="handleSpatialDataObjectsInput(element.id, $event)"></textarea>
          </td>
        </ng-container>
        <ng-container matColumnDef="sites">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sites (1 per line)</th>
          <td mat-cell *matCellDef="let element">
            <textarea *ngIf="element.id == selectedSpatialDataGroupId && element.sites" cols="40" rows="5"
              style="width: 100%" [value]="element.sites.join('\n')"
              (input)="handleSitesUpdate(element, $event)"></textarea>
            <p *ngIf="element.id != selectedSpatialDataGroupId">
              {{ element.sites.length }}
            </p>
          </td>
        </ng-container>
        <ng-container matColumnDef="siteNames">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>SiteNames</th>
          <td mat-cell *matCellDef="let element">
            <pre>{{ getSiteNames(element.sites) }}</pre>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle [checked]="selectedSpatialDataGroupId == element.id"
              (change)="handleEditChange(element.id)">Edit</mat-slide-toggle>
            <br />
            <br />
            <mat-slide-toggle [checked]="isRenderedInMap(element.id)" (change)="toggleRenderInMap(element.id)">Render in
              map</mat-slide-toggle>
            <br />
            <br />
            <button mat-raised-button (click)="save(element)">Save</button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <div id="map" style="width: 100%; height: 800px; margin-bottom: 20px;"></div>

  </div>

  <ng-template #nullTemplate>Null</ng-template>

  <ng-template #loading>
    <div class="loading-indicator">
      <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </div>
  </ng-template>
</div>
