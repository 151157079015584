import { Component, OnInit, Input, Inject } from '@angular/core';
import {
  HardwareService,
  HardwareDevice,
  HardwareDeviceSearch,
  NewHardwareDevice,
  HardwareEvent,
  HardwareEventType,
} from '../services/hw.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-hardwareoverview',
  templateUrl: './hardwareoverview.component.html',
  styleUrls: ['./hardwareoverview.component.css'],
})
export class HardwareOverviewComponent implements OnInit {
  constructor(public snackBar: MatSnackBar, private hwService: HardwareService, public dialog: MatDialog) {}
  standardQueryForm: HardwareDeviceSearch = {
    Serial: '',
    MacAddress: '',
    Limit: 100,
    Page: 0,
    OrderBy: 'Id',
    OrderDirection: 'asc',
  };
  queryForm = Object.assign(this.standardQueryForm);

  displayedColumns = [
    'select',
    'id',
    'serial',
    'macAddress',
    'bleMacAddress',
    'deviceType',
    'siteName',
    'lastObserved',
    'options',
  ];
  public hardwareDevices: HardwareDevice[];
  public selectedHardware: HardwareDevice[] = [];

  queryHardwareFormSubmit(search: HardwareDeviceSearch) {
    this.queryForm = {
      ...this.standardQueryForm,
      Limit: this.queryForm.Limit,
      OrderBy: this.queryForm.OrderBy,
      OrderDirection: this.queryForm.OrderDirection,
      ...search,
    };
    this.getHardwareDevices();
  }

  ngOnInit() {
    this.getHardwareDevices();
  }

  public getHardwareDevices(): void {
    this.hwService.searchHardware(this.queryForm).subscribe((data) => {
      this.hardwareDevices = data;
    });
  }

  onBulkAddEventClick() {
    console.log(this.selectedHardware);
    const addHardwareDialogRef = this.dialog.open(BulkAddHardwareEventComponent, {
      width: '80%',
      data: {
        selectedHardware: this.selectedHardware,
      },
    });

    addHardwareDialogRef.afterClosed().subscribe((s) => {});
  }

  onNewHardwareClick() {
    const addHardwareDialogRef = this.dialog.open(NewHardwareDeviceComponent, {
      width: '80%',
    });

    addHardwareDialogRef.afterClosed().subscribe((s) => {
      this.getHardwareDevices();
    });
  }
}

@Component({
  selector: 'app-bulk-add-events',
  template: `<mat-card appearance="outlined">
    <h3>Bulk add events</h3>
    <form (ngSubmit)="generateEvents($event)">
      <mat-form-field>
        <mat-select [(ngModel)]="newEventData.hardwareEventTypeId" name="hardwareEventTypeId" placeholder="Event Type">
          <mat-option *ngFor="let eventType of hardwareEventTypes" [value]="eventType.id">
            {{ eventType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field style="width:400px;">
        <input matInput type="text" [(ngModel)]="newEventData.comment" name="comment" placeholder="comment" />
      </mat-form-field>
      <button mat-button type="submit">Generate events</button>
    </form>
    <div>
      <h4>Sample output (first 5 events)</h4>
      <pre>
                  {{ newEvents.slice(0, 5) | json }}
                </pre
      >
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="onSaveClick()">
        Add event to {{ newEvents.length }} selected hardwareDevices
      </button>
      <button mat-button (click)="onCancelClick()">Cancel</button>
    </mat-card-actions>
  </mat-card> `,
})
export class BulkAddHardwareEventComponent implements OnInit {
  public selectedHardware: HardwareDevice[];
  public newEventData: HardwareEvent = {
    hardwareEventTypeId: null,
    comment: '',
  };
  public newEvents: HardwareEvent[] = [];
  public hardwareEventTypes: Array<HardwareEventType>;

  constructor(
    public dialogRef: MatDialogRef<BulkAddHardwareEventComponent>,
    private hwService: HardwareService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { selectedHardware: HardwareDevice[] }
  ) {
    this.selectedHardware = data.selectedHardware;
  }

  ngOnInit(): void {
    // Get hardwareDeviceEventTypes for adding new events
    this.hwService.getHardwareEventTypes().subscribe((eventTypes) => {
      this.hardwareEventTypes = eventTypes as HardwareEventType[];
    });
  }

  generateEvents(e: Event) {
    e.preventDefault();
    this.newEvents = [];

    this.selectedHardware.forEach((hd) => {
      const tmpEvent: HardwareEvent = {
        hardwareDeviceId: hd.id,
        hardwareEventTypeId: this.newEventData.hardwareEventTypeId,
        comment: this.newEventData.comment,
      };
      this.newEvents.push(tmpEvent);
    });
    console.log(this.selectedHardware);
    console.log(this.newEventData);
    console.log(this.newEvents);
  }

  onSaveClick(): void {
    this.hwService.bulkSaveHardwareEvent(this.newEvents).subscribe(
      (s) => {
        this.snackBar.open('Events added', null, { duration: 3000 });
        this.onCancelClick();
      },
      (error) => {
        console.error(error);
        let errMsg: string;
        if (error.hasOwnProperty('error')) {
          errMsg = error.error;
        }
        this.snackBar.open('Error: ' + errMsg, null, { duration: 3000 });
      }
    );
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-new-hardware-dialog',
  template: `<mat-card appearance="outlined">
    <h3>Add new hardwareDevices</h3>
    <form (ngSubmit)="generateDevices($event)">
      <mat-form-field style="width:300px;">
        <input
          matInput
          required
          name="startSerial"
          [(ngModel)]="newHardwareData.startSerial"
          type="text"
          placeholder="StartSerial"
        />
      </mat-form-field>
      <mat-form-field style="width:300px;">
        <input
          matInput
          required
          name="stopSerial"
          [(ngModel)]="newHardwareData.stopSerial"
          type="text"
          placeholder="StopSerial"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-select name="deviceType" placeholder="DeviceType" [(ngModel)]="newHardwareData.deviceType">
          <mat-option *ngFor="let deviceType of hardwareDeviceTypes" [value]="deviceType">
            {{ deviceType }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button type="submit">Generate devices</button>
    </form>

    <mat-divider></mat-divider>
    Insert with csv will look for serial in database, if it exists, then it's fields will be updated. Instead of adding
    a new device with same serial.
    <br />
    <p>Example csv for one wearable and one node:</p>
    <pre>
DateTime;MacAddress;BLEMacAddress;Serial;DeviceType
2021-12-07T12:23:00Z;;ec:b0:3c:81:a7:6c;ec:b0:3c:81:a7:6c;SRT-PT
2021-12-07T12:23:00Z;00:12:4b:00:1e:1d:de:51;some-ble-mac;00:12:4b:00:1e:1d:de:51;SRT-MN</pre
    >

    <form (ngSubmit)="generateDevicesFromCsv(csvForm)" #csvForm="ngForm">
      <mat-form-field style="width:150px;">
        <input matInput ngModel required name="csvDelimeter" type="text" placeholder="Delimeter ',' ';' '  '" />
      </mat-form-field>
      <mat-form-field style="margin-left:20px;">
        <textarea
          ngModel
          required
          matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
          name="csvText"
          placeholder="CSV with headers."
        ></textarea>
      </mat-form-field>
      <button mat-raised-button type="submit">Generate devices from csv</button>
    </form>
    <div>
      <h4>Sample output (first 5 devices)</h4>
      <pre>
                  {{ newHardware.slice(0, 5) | json }}
                </pre
      >
    </div>
    <mat-card-actions *ngIf="!loading">
      <button mat-raised-button (click)="onSaveClick()">Add {{ newHardware.length }} new hardwaredevices</button>
      <button mat-button (click)="onCancelClick()">Cancel</button>
    </mat-card-actions>
    <div *ngIf="loading">
      <mat-spinner></mat-spinner>
      This might take a minute.
    </div>
  </mat-card> `,
})
export class NewHardwareDeviceComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NewHardwareDeviceComponent>,
    private hwService: HardwareService,
    private snackBar: MatSnackBar
  ) {}
  public newHardwareData = {
    startSerial: '',
    stopSerial: '',
    deviceType: '',
  };
  public newHardware: NewHardwareDevice[] = [];
  public hardwareDeviceTypes: string[] = [];

  loading = false;

  ngOnInit() {
    this.hwService.getHardwareDeviceType().subscribe((types) => {
      this.hardwareDeviceTypes = types;
    });
  }

  generateDevices(e: Event) {
    e.preventDefault();
    this.newHardware = [];
    const startSerial: String[] = this.newHardwareData.startSerial.split('-');
    const stopSerial: String[] = this.newHardwareData.stopSerial.split('-');
    console.log(this.newHardwareData);
    const deviceType = this.newHardwareData.deviceType;

    const numberOfSerials = Number(stopSerial[1]) - Number(startSerial[1]) + 1;
    for (let i = 0; i < numberOfSerials; i++) {
      const tmpHardware: NewHardwareDevice = {
        serial: startSerial[0] + '-' + (Number(startSerial[1]) + i).toString(),
        deviceType: deviceType,
      };

      this.newHardware.push(tmpHardware);
    }
  }

  generateDevicesFromCsv(form: NgForm) {
    const key: string = form.value.key;
    const csv: string = form.value.csvText;
    const delimeter: string = form.value.csvDelimeter;

    const lines = csv.split('\n');
    const result: NewHardwareDevice[] = [];
    const headers = lines[0].split(delimeter);

    for (let i = 1; i < lines.length; i++) {
      const line = lines[i].split(delimeter);
      const obj: NewHardwareDevice = {};
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = line[j];
      }

      result.push(obj);
    }
    console.log(key);
    console.log(delimeter);
    console.log(result);
    this.newHardware = result;
  }

  onSaveClick(): void {
    this.loading = true;
    this.hwService.postHardware(this.newHardware).subscribe(
      (s) => {
        this.snackBar.open(s, null, { duration: 10000 });
        this.loading = false;
        this.onCancelClick();
      },
      (error) => {
        console.error(error);
        let errMsg: string;
        if (error.hasOwnProperty('error')) {
          this.loading = false;
          errMsg = error.error;
        }
        this.snackBar.open('Error: ' + errMsg, null, { duration: 10000 });
      }
    );
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
}
