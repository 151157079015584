import { HardwareDeviceSearch } from './hw.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { EdgeDevice } from './site.service';

@Injectable()
export class EdgeDeviceService {
  constructor(private httpClient: HttpClient) {}

  get(search: HardwareDeviceSearch): Observable<EdgeDevice[]> {
    return this.httpClient.get<EdgeDevice[]>(environment.apiAddress + '/edgedevice');
  }
  getById(id: string): Observable<EdgeDevice> {
    return this.httpClient.get<EdgeDevice>(environment.apiAddress + '/edgedevice/' + id);
  }
  putById(edgeDevice: EdgeDevice): Observable<string> {
    return this.httpClient.put<string>(environment.apiAddress + '/edgedevice/' + edgeDevice.id, edgeDevice);
  }
  uploadDeviceLogs(id: string, payload: object): Observable<string> {
    return this.httpClient.post(environment.apiAddress + '/edgedevice/' + id + '/uploadlogs', payload, {
      responseType: 'text',
    });
  }
  connectToIotHubEdgeDevice(id: string): Observable<string> {
    return this.httpClient.post(environment.apiAddress + '/edgedevice/' + id + '/IotEdgeDevice', null, {
      responseType: 'text',
    });
  }
  putIotHubEdgeDeviceTags(id: string, tags: object): Observable<object> {
    return this.httpClient.put<object>(environment.apiAddress + '/edgedevice/' + id + '/IotEdgeDevice/tags', tags);
  }
  connectToAzureAd(id: string): Observable<string> {
    return this.httpClient.post(environment.apiAddress + '/edgedevice/' + id + '/azuread', null, {
      responseType: 'text',
    });
  }
  deleteById(id: string): Observable<any> {
    return this.httpClient.delete(environment.apiAddress + '/edgedevice/' + id);
  }
}
