<h1>Customers (Organizations):</h1>
<mat-grid-list cols="2" rowHeight="60px">
  <mat-grid-tile>
    <form (submit)="search()">
      <mat-form-field style="width: 300px">
        <input matInput placeholder="Customername" name="input" [(ngModel)]="customerSearch.name" />
      </mat-form-field>
      <button mat-raised-button (click)="search()">Search</button>
    </form>
  </mat-grid-tile>
  <mat-grid-tile>
    <button mat-raised-button color="primary" (click)="newCustomer()">Create customer</button>
  </mat-grid-tile>
</mat-grid-list>

<div>
  <div *ngIf="customers; let customer; else: loading">
    <mat-paginator
      #paginator
      [(pageSize)]="customerSearch.Limit"
      [pageIndex]="customerSearch.Page"
      [pageSizeOptions]="[30, 50, 100, 300]"
      [length]="1000"
      (page)="onPageChange($event)"
    >
    </mat-paginator>
    <mat-table #table matSort (matSortChange)="onSortChange($event)" [dataSource]="customersDataSource">
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Id </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="sites">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Sites </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.numberOfSites }} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        [routerLink]="[row.id]"
        style="cursor: pointer"
      ></mat-row>
    </mat-table>
  </div>

  <ng-template #loading>
    <div class="loading-indicator">
      <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </div>
  </ng-template>
</div>
