<button mat-button color="primary" (click)="connectEdgedevice()">Connect edgedevice</button>
<button mat-button (click)="onDisconnectSelectedClick()" color="warn">
  Remove {{ selected.selected.length }} edgedevices.
</button>
<mat-table #edgeDeviceTable [dataSource]="edgeDevices">
  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef class="w-64">
      <mat-checkbox
        (change)="$event ? masterToggle() : null"
        [checked]="selected.hasValue() && isAllSelected()"
        [indeterminate]="selected.hasValue() && !isAllSelected()"
      >
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let row" class="w-64">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? selected.toggle(row) : null"
        [checked]="selected.isSelected(row)"
      >
      </mat-checkbox>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
    <mat-cell *matCellDef="let element"
      ><button mat-button [routerLink]="[element.id]">{{ element.id }}</button></mat-cell
    >
  </ng-container>
  <ng-container matColumnDef="authTenantId">
    <mat-header-cell *matHeaderCellDef> Tenant Id </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.authTenantId }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="azureIotEdgeDeviceId">
    <mat-header-cell *matHeaderCellDef> azureIotEdgeDeviceId </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.azureIotEdgeDeviceId }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="azureIotHubHostName">
    <mat-header-cell *matHeaderCellDef> AzureIotHubHostName </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.azureIotHubHostName }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="authApplicationId">
    <mat-header-cell *matHeaderCellDef> Application Id </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.authApplicationId }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="options">
    <mat-header-cell *matHeaderCellDef> Options </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button mat-button color="warn" (click)="onDisconnectClick(element)">Disconnect</button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
