import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { queryToParams } from '../../utils/queryToParams';
import { Site } from './site.service';

@Injectable()
export class HardwareService {
  constructor(private httpClient: HttpClient) {}

  getAllHardware(): Observable<HardwareDevice> {
    return this.httpClient.get<HardwareDevice>(environment.apiAddress + '/hardwaredevice');
  }
  searchHardware(search?: HardwareDeviceSearch): Observable<HardwareDevice[]> {
    const queryString = search ? queryToParams(search) : '';

    return this.httpClient.get<HardwareDevice[]>(environment.apiAddress + '/hardwaredevice' + queryString);
  }
  getHardware(id: any): any {
    return this.httpClient.get(environment.apiAddress + '/hardwaredevice/' + id);
  }

  getHardwareEvent(id: string, search?: HardwareDeviceEventSearch): Observable<HardwareEvent[]> {
    const queryString = search ? queryToParams(search) : '';

    return this.httpClient.get<HardwareEvent[]>(
      environment.apiAddress + '/hardwaredevice/' + id + '/event' + queryString
    );
  }

  getHardwareDeviceType(): Observable<string[]> {
    return this.httpClient.get<string[]>(environment.apiAddress + '/hardwaredevice/devicetype');
  }

  getHwDiagnostic(id: string): Observable<HwDiagnostic[]> {
    return this.httpClient.get<HwDiagnostic[]>(environment.apiAddress + '/hardwaredevice/' + id + '/hwdiagnostic');
  }

  saveHardwareEvent(data: HardwareEvent): Observable<any> {
    return this.httpClient.post(environment.apiAddress + '/hardwaredevice/' + data.hardwareDeviceId + '/event', data);
  }

  bulkSaveHardwareEvent(data: HardwareEvent[]): Observable<any> {
    return this.httpClient.post(environment.apiAddress + '/hardwaredevice/events', data);
  }

  getHardwareEventTypes(): Observable<HardwareEventType[]> {
    return this.httpClient.get<HardwareEventType[]>(environment.apiAddress + '/hardwareeventtypes');
  }

  postHardware(newHardware: NewHardwareDevice[]): Observable<string> {
    return this.httpClient.post(environment.apiAddress + '/hardwaredevice/', newHardware, { responseType: 'text' });
  }
  saveHardware(hardware: HardwareDevice): Observable<any> {
    return this.httpClient.put(environment.apiAddress + '/hardwaredevice/' + hardware.id, hardware);
  }
  deleteHardware(HardwareId: string): Observable<any> {
    return this.httpClient.delete(environment.apiAddress + '/hardwaredevice/' + HardwareId);
  }
}

export interface Search {
  Limit?: number | null;
  Page?: number | null;
  OrderBy?: string;
  OrderDirection?: string;
}

export interface HardwareDeviceSearch extends Search {
  Id?: string;
  Serial?: string;
  StartSerial?: string;
  StopSerial?: string;
  MacAddress?: string;
  BLEMacAddress?: string;
  DeviceType?: string;
  Loc?: string;
  Currloc?: string;
  Event?: string;
  NotEvent?: string;
  Comment?: string;
}

export interface HardwareDeviceEventSearch extends Search {
  Id?: string;
  HardwareEventTypeId?: number | null;
}

export interface HardwareDevice {
  id: string;
  serial: string;
  macAddress: string;
  bleMacAddress: string;
  deviceType: string;
  events: HardwareEvent[];
  lastObserved: Date;
  site: Site | null;
  createdAt: Date | string;
  lastUpdatedAt: Date | string;
}

export interface NewHardwareDevice {
  serial?: string;
  macAddress?: string;
  bleMacAddress?: string;
  deviceType?: string;
}

export interface HardwareEvent {
  hardwareDeviceId?: string;
  id?: string;
  createdAt?: Date;
  hardwareEventTypeId?: number;
  eventType?: string;
  comment: string;
}

export interface HardwareEventType {
  id: number;
  name: string;
}

export interface HwDiagnostic {
  siteid?: string;
  static_customerid?: string;
  static_customername?: string;
  static_sitename?: string;
  static_hwid?: string;
  static_hwmac?: string;
  static_hwserial?: string;
  prefix?: string;
  sequence?: number;
  instance_id?: number;
  lifetime?: number;
  prefixlen?: number;
  flags?: number;
  node_version?: number;
  ble_version?: number;
  node_uptime?: number;
  ble_uptime?: number;
  parent_addr?: string;
  ts?: number;
  msgtype?: number;
  hwmac?: string;
  batt?: number;
  temp?: number;
  uptime?: number;
  sampleprop?: string;
  EventProcessedUtcTime?: Date;
  PartitionId?: number;
  EventEnqueuedUtcTime?: Date;
  IoTHub?: IoTHub;
  id?: string;
  _rid?: string;
  _self?: string;
  _etag?: string;
  _attachments?: string;
  _ts?: number;
}

export interface IoTHub {
  MessageId?: null;
  CorrelationId?: null;
  ConnectionDeviceId?: string;
  ConnectionDeviceGenerationId?: string;
  EnqueuedTime?: Date;
  StreamId?: null;
}
