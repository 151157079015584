<h1>Sensor Type Definitions</h1>
<p>
  Currently restricted to adding and modifying ModbusConfigurations, will include BLE adding SensorTypes at some point.
</p>
<button mat-raised-button color="primary" (click)="openDialog()">Add new modbus configuration</button>
<br />
<br />
<div *ngIf="sensorTypes; let sensorTypes; else: loading">
  <table mat-table [dataSource]="sensorTypes" matSort class="mat-elevation-z8" style="width: 100%">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="sensorTypeId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>SensorTypeId</th>
      <td mat-cell *matCellDef="let element">{{ element.sensorTypeId }}</td>
    </ng-container>
    <ng-container matColumnDef="revision">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Revision</th>
      <td mat-cell *matCellDef="let element">{{ element.revision }}</td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let element">{{ element.description }}</td>
    </ng-container>

    <!-- Columns below will probably be moved to details page -->
    <ng-container matColumnDef="measurementDefinitions">
      <th mat-header-cell *matHeaderCellDef>MeasurementDefinitions</th>
      <td mat-cell *matCellDef="let element">
        <textarea
          *ngIf="element.measurementDefinitions; else nullTemplate"
          readonly
          cols="20"
          rows="5"
          style="width: 100%"
          >{{ element.measurementDefinitions | json }}</textarea
        >
      </td>
    </ng-container>
    <ng-container matColumnDef="modbusDeviceType">
      <th mat-header-cell *matHeaderCellDef>ModbusDeviceType</th>
      <td mat-cell *matCellDef="let element">
        <textarea *ngIf="element.modbusDeviceType; else nullTemplate" readonly cols="20" rows="5" style="width: 100%">{{
          element.modbusDeviceType | json
        }}</textarea>
      </td>
    </ng-container>
    <ng-container matColumnDef="sourceJson">
      <th mat-header-cell *matHeaderCellDef>SourceJson</th>
      <td mat-cell *matCellDef="let element">
        <textarea *ngIf="element.sourceJson; else nullTemplate" readonly cols="20" rows="5" style="width: 100%">{{
          element.sourceJson | json
        }}</textarea>
      </td>
    </ng-container>

    <ng-container matColumnDef="clone">
      <th mat-header-cell *matHeaderCellDef>Clone</th>
      <td mat-cell *matCellDef="let element">
        <button *ngIf="element.sourceJson" mat-raised-button (click)="openDialog(element.sourceJson)">Clone</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <!-- TODO: Link to details page for sensorType -->
    <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['site/'+row.id+'/details']" style="cursor:pointer;"></tr> -->
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<ng-template #nullTemplate>Null</ng-template>

<ng-template #loading>
  <div class="loading-indicator">
    <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
  </div>
</ng-template>
